@value orange from 'css/Colors.module.css';
@value lightGray from 'css/Colors.module.css';

* {
  line-height: 130%;
}

.container {
  padding: 20px;
  &__item {
    &:not(:last-child) {
      border-bottom: 1px lightGray solid;
      padding-bottom: 13px;
    }
    &:not(:first-child) {
      padding-top: 13px;
    }
  }
}

.header {
  display: flex;
  grid-gap: 20px;
  justify-content: space-between;
}

.date {
  font-weight: 700;
}

.campTitle {
  margin-top: 10px;
}

.content {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
}

.sport {
  font-weight: 700;
}

.footer {
  height: 50px;
  background-color: #F1F2F4;
  display: flex;
  align-items: center;
  grid-gap: 10px;
  padding: 20px;
  border-top: 1px lightGray solid;
  margin-top: auto;
}

.city {
  font-weight: 700;
}

.moreButton {
  color: orange;
  font-weight: 500;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: auto;
  &:hover {
    &:after {
      transform: translateX(10px);
      transition: transform .3s;
    }
  }
  &:after {
    content: "";
    display: block;
    height: 24px;
    width: 24px;
    margin-left: 8px;
    background-image: url("/components/Pages/Home/img/card-game-arrow.svg");
    background-size: cover;
    background-position: center;
    transition: transform .3s;
  }
}

.logo {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  border: 1px lightGray solid;
  overflow: hidden;
  flex-shrink: 0;
  &__img {
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.linkWrapper {
  text-decoration: none;
  color: initial;
  margin-top: auto;
  &:visited {
    color: initial;
  }
}