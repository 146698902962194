.label {
  display: flex;
  cursor: pointer;
}

.input {
  display: none;

  & + .checkbox {
    position: relative;
    box-sizing: border-box;
    width: 18px;
    height: 18px;
    border: 2px solid #FF6620;
    border-radius: 3px;
    display: block;
    cursor: pointer;
    transition: transform 0.2s;

    &:after {
      content: " ";
      display: block;
      position: absolute;
      top: 3px;
      left: 2px;
      height: 4px;
      width: 8px;
      border: 2px solid white;
      border-top: none;
      border-right: none;
      transform: rotate(-45deg);
      opacity: 0;
    }

    &:hover {
      transform: scale(1.06);
    }
  }
  &:checked + .checkbox {
    background: #FF6620;
    transition: background .15s ease;

    &:after {
      opacity: 1;
      transition: opacity .15s ease;
    }
  }

  &.ambiguous:checked + .checkbox {
    background: transparent;

    &::after {
      top: 6px;
      left: 2px;
      width: 10px;
      height: 2px;
      background: #FF6620;
      border: none;
      transform: none;
    }
  }
}

.labelText {
  margin-left: 8px;
  color: #303337;
  font-size: 14px;
  position: relative;
  z-index: 1;
}

@media (min-width: 840px) {
  .labelText {
    font-size: 16px;
  }
}