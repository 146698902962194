.container {
  background-color: #ffffff;
  border: 1px solid #e2e2e2;
  box-shadow: 0 1px 5px 0 rgba(0,0,0,.16);
  border-radius: 8px;
  padding: 15px;
  min-height: 120px;
  display: flex;
  grid-gap: 20px;
}

.content {
  display: flex;
  grid-gap: 20px;
  align-items: center;
}

.imgWrapper {
  width: 70px;
  height: 70px;
  border-radius: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 18px;
  text-decoration: none;
  flex-shrink: 0;
  &:hover {
    filter: brightness(.85);
  }
}

.textWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-gap: 5px;
}

.playerName {
  font-size: 15px;
  font-weight: 700;
  line-height: 24px;
  color: #4a4a4a;
}

.action {
  cursor: pointer;
  display: inline-flex;
  &.withIcon {
    position: relative;
    padding-left: 20px;
    display: flex;
    align-items: center;
    &:before {
      content: '';
      display: block;
      background: url('../img/send-ticket-mail.svg') no-repeat;
      height: 10px;
      width: 10px;
      position: absolute;
      left: 0;
      transform: translateY(-2px);
    }
  }
  &.sendTicket {
    &:before {
      background: url('../img/send-ticket-mail.svg') no-repeat;
    }
  }
  &.exit {
    &:before {
      background: url('../img/exit-game.svg') no-repeat;
      transform: translateY(-1px);
    }
  }
  &__text {
    font-size: 13px;
    border-bottom: 1px dotted #979797;
  }
  &:hover {
    .action {
      &__text {
        border-color: transparent;
        color: #ff4317;
      }
    }
  }
}

.backButton {
  cursor: pointer;
  width: 20px;
  flex-shrink: 0;
}