.radioButtonGroup {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
  &__item {
    margin: 5px;
    padding-left: 30px;
    position: relative;
    cursor: pointer;
  }
  &__input {
    display: none;
    & + .checkbox {
      width: 20px;
      height: 20px;
      border: 1px #949494 solid;
      border-radius: 3px;
      display: block;
      position: absolute;
      left: 0;
      z-index: 0;
      background: #f3f3f3;
      &:after {
        content: ' ';
        display: block;
        border: 2px solid #ff7559;
        border-left: none;
        border-top: none;
        opacity: 0;
        height: 9px;
        width: 4px;
        transform: rotate(45deg);
        left: 6px;
        top: 2px;
        position: absolute;
        transition: opacity .15s ease;
      }
    }
    &:checked + .checkbox {
      &:after {
        opacity: 1;
        transition: opacity .15s ease;
      }
    }
  }
  &__title {
    font-size: 16px;
    font-weight: 900;
    color: #484848;
  }
  &__text {
    margin: 0;
    position: relative;
    z-index: 1;
  }

  &.inbox {
    .radioButtonGroup {
      &__item {
        width: 90px;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        &.light {
          .radioButtonGroup {
            &__text {
              &:before {
                background-image: url("/imgs/level-light.svg");
              }
            }
          }
        }
        &.medium {
          .radioButtonGroup {
            &__text {
              &:before {
                background-image: url("/imgs/level-medium.svg");
              }
            }
          }
        }
        &.hard {
          .radioButtonGroup {
            &__text {
              &:before {
                background-image: url("/imgs/level-hard.svg");
              }
            }
          }
        }
      }
      &__text {
        &:before {
          content: '';
          display: block;
          width: 18px;
          height: 14px;
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 5px;
        }
      }
      &__input {
        & + .checkbox {
          width: 100%;
          height: 100%;
          &:after {
            content: '';
            display: block;
            position: absolute;
            left: -2px;
            top: -2px;
            width: 100%;
            height: 100%;
            box-shadow: 0 1px 4px 0 rgb(0 0 0 / 12%);
            border: solid 2px #ffaa59;
            opacity: 0;
            transform: none;
            border-radius: 3px;
          }
        }
        &:checked + .checkbox {
          &:after {
            opacity: 1;
          }
        }
      }
    }
  }




}