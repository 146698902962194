@import "../../../../../../css/mixins/mixins";

.modal {
  max-width: 600px !important;
}

.container {
  display: flex;
  @include adaptive(mob) {
    flex-direction: column;
  }
}
.imgWrapper {
  border-radius: 100%;
  width: 130px;
  height: 130px;
  overflow: hidden;
  border: solid 2px #e2e2e2;
  flex-shrink: 0;
}

.img {
  width: 100%;
}

.list {
  list-style-type: none;
  margin: 0;
  @include adaptive(mob) {
    padding: 0;
    margin-top: 40px;
  }
  li {
    font-size: 14px;
    line-height: 150%;
    &:not(:first-child) {
      margin-top: 10px;
    }
    .value {
      font-weight: 700;
      color: #2b313b;
    }
    a.value {
      color: #FF6620;
      border-bottom: 1px solid #FF6620;
      text-decoration: none;
    }
  }
}