.lightblue {
    background-image: linear-gradient(to bottom, #56e6f2, #33b4ee) !important;
}

.pink {
    background-image: linear-gradient(to bottom, #f7a2cc, #e05be9) !important;
}

.violet {
    background-image: linear-gradient(to bottom, #dc8cfb, #8253f4) !important;
}

.orange {
    background-image: linear-gradient(to bottom, #ffe284, #ff8a5e) !important;
}

.green {
    background-image: linear-gradient(to bottom, #5af298, #32c9ab) !important;
}

.lightgreen {
    background-image: linear-gradient(to bottom, #b7eb89, #5ecb69) !important;
}

.red {
    background-image: linear-gradient(to bottom, #ff9689 0%, #fe5773) !important;
}