.list {
    padding-left: 16px;
    padding-right: 24px;
    margin-top: 28px;
}

@media (min-width: 840px) {
    .list {
        margin-top: 8px;
        padding-right: 20px;
        padding-left: 20px;
    }
}