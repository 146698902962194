.paginator {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
}

button.arrowButton {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  flex-grow: 0;
  width: 28px;
  height: 28px;
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;

  &::before {
    content: "";
    width: 8px;
    height: 12px;
    background-image: url("/imgs/arrow.svg");
    background-size: 8px 12px;
    background-repeat: no-repeat;
    background-position: center;
  }

  &.right {
    &::before {
      transform: rotate(180deg);
    }
  }

  &[disabled] {
    opacity: 0.4;
  }
}

.arrowButtonText {
  display: none;
}

.pageButtons {
  display: flex;
  flex-shrink: 0;
  flex-grow: 0;
}

@media (min-width: 840px) {
  button.arrowButton {
    width: auto;
    font-size: 14px;

    &::before {
      margin-right: 12px;
    }

    &.right {
      flex-direction: row-reverse;

      &::before {
        margin-right: 0;
        margin-left: 12px;
      }
    }
  }

  .arrowButtonText {
    display: inline;
  }
}