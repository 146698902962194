.img {
  width: 38px;
  height: 38px;
  cursor: default;
}

.text {

}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 10px;
}