@import "../../../../../css/mixins/mixins";

.container {
  width: 360px;
  background-color: #ffffff;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  overflow-y: scroll;
  z-index: 2;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0,0,0,0.2);
  &.positionFixed {
    position: fixed;
    z-index: 99999;
  }
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  &::-webkit-scrollbar-track {
    background-color: #f0f0f0;
  }
  &::-webkit-scrollbar-thumb {
    height: 30%;
    background-color: #d0d0d0;
  }
  @include adaptive(mob) {
    width: 100%;
    &:not(.positionFixed) {
      left: 0;
      right: auto;
      width: calc(100% + 10px);
      padding: 0;
      padding-right: 10px;
      box-shadow: none;
    }
  }
}

.title {
  font-size: 20px;
  font-weight: bold;
}

.placemark {
  width: 22px;
}

.locationRow {
  display: flex;
  grid-gap: 10px;
  align-items: center;
  margin-top: 10px;
}

.location {
  font-size: 16px;
}

.items {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
  @include adaptive(mob) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  @include adaptive(mob2) {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}

.crossIcon {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  opacity: .7;
  &:hover {
    opacity: 1;
  }
  @include adaptive(mob) {
    top: 5px;
    right: 10px;
  }
}

.arenaName {
  font-size: 18px;
  font-weight: bold;
  margin-top: 20px;
}