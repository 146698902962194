.label {
  margin: 5px;
  padding-left: 30px;
  position: relative;
  cursor: pointer;
  &.inbox {
    padding: 0;
    width: 60px;
    height: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
    input {
      & + .checkbox {
        width: 100%;
        height: 100%;
        &:after {
          content: '';
          display: block;
          position: absolute;
          left: -2px;
          top: -2px;
          width: 100%;
          height: 100%;
          box-shadow: 0 1px 4px 0 rgb(0 0 0 / 12%);
          border: solid 2px #ffaa59;
          opacity: 0;
          transform: none;
          border-radius: 3px;
        }
      }
      &:checked + .checkbox {
        &:after {
          opacity: 1;
        }
      }
    }
    .labelText {
      font-size: 14px;
    }
  }
  &.disabled {
    pointer-events: none;
    opacity: .6;
  }
}

.input {
  display: none;
  & + .checkbox {
    width: 20px;
    height: 20px;
    border: 1px #949494 solid;
    border-radius: 3px;
    display: block;
    position: absolute;
    left: 0;
    z-index: 0;
    background: #f3f3f3;

    &:after {
      content: ' ';
      display: block;
      border: 2px solid #ff7559;
      border-left: none;
      border-top: none;
      opacity: 0;
      height: 9px;
      width: 4px;
      transform: rotate(45deg);
      left: 6px;
      top: 2px;
      position: absolute;
      transition: opacity .15s ease;
    }
  }
  &:checked + .checkbox {
    &:after {
      opacity: 1;
      transition: opacity .15s ease;
    }
  }

  &.ambiguous:checked + .checkbox {
  }
}

.labelText {
  margin: 0;
  position: relative;
  z-index: 1;
}

