@import 'src/css/mixins/mixins';

.games {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 48px 30px;
    @include adaptive(mob) {
        padding: 30px 20px;
    }
}

.gridItem {
    width: calc(1/3*100% - (1 - 1/3)*20px);
}

.gameHint {
    border: 1px dashed #C4CAD4;
    border-radius: 16px;
    flex: 0 0 calc(1/3*100% - (1 - 1/3)*20px);
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 120px;
}

.gameHintCenter {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    font-size: 14px;
}

.archiveFilters {
    display: flex;
    gap: 10px;
    padding: 48px 31px 0;
    margin-bottom: -12px;
}

@media (max-width: 1120px) {
    .gridItem {
        width: calc(1/2*100% - (1/2)*20px);
    }

    .gameHint{
        flex: 0 0 calc(1/2*100% - (1/2)*20px);
    }
}

@media (max-width: 900px) {
    .gridItem {
        width: calc(100% - 20px);
    }

    .gameHint {
        flex: 0 0 calc(100% - 20px);
        min-height: 124px;
    }

    .games {
        flex-direction: column;
    }

    .archiveFilters {
        flex-direction: column;
    }
}