.backLink {
    display: flex;
    margin-bottom: 32px;
    cursor: pointer;
}

.backLink:hover {
    opacity: 0.8;
}

.backLinkText {
    line-height: 24px;
    padding-left: 6px;
    font-size: 14px;
    font-weight: 500;
}