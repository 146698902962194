.signIn__restore-pass {
  text-align: center;
  margin: -5px 0 32px;
  cursor: pointer;
  text-decoration-line: underline;
  transition: color 0.2s linear;
}

.signIn__restore-pass:hover {
  color: #ff854d;
}

.signIn__restore-pass:active {
  color: #d95316;
}

.signIn__another {
  text-align: center;
  color: #6b7683;
  margin: 28px 0 24px;
  letter-spacing: 0.4px;
}

.signIn__social-list {
  display: flex;
  align-items: center;
  justify-content: center;
}

.signIn__social-link {
  text-decoration: none;
  transition: opacity 0.2s linear;
  cursor: pointer;
}

.signIn__social-link:hover {
  opacity: 0.75;
}

.signIn__social-link:active {
  opacity: 0.3;
}

.social-icon {
  display: block;
  width: 40px;
  height: 40px;
  margin: 0 13px;
  background: 50% 50% no-repeat;
}

.social-icon_vk {
  background-image: url('./img/vk-icon.svg');
}

.social-icon_fb {
  background-image: url('./img/fb-icon.svg');
}

.social-icon_google {
  background-image: url('./img/google-icon.svg');
}

.social-icon_apple {
  background-image: url('img/apple-icon.svg');
}

.auth-tabs {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
}

.auth-tabs__item {
  padding-bottom: 4px;
  width: 120px;
  margin: 0 12px;
  text-align: center;
  color: #6b7683;
  line-height: 1.4;
  font-size: 18px;
  font-weight: bold;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  transition: color 0.2s linear;
}

.auth-tabs__item:hover {
  color: #2f3441;
}

.auth-tabs__item_active {
  color: #2f3441;
  border-bottom: 2px solid #ff6620;
}

.auth__phone-text {
  font-size: 12px;
  line-height: 1.2;
  letter-spacing: 0.3px;
  color: #6b7683;
  margin: 0 0 16px;
  padding-right: 15px;
}

.auth__agreement-message {
  font-size: 14px;
  line-height: 1.5;
  color: #2f3441;
  margin: 30px 0 32px;
  letter-spacing: 0.4px;
}

.auth__agreement-message a {
  color: #ff6620;
  transition: color 0.2s linear;
}

.auth__agreement-message a:hover {
  color: #ff854d;
}

.auth__agreement-message a:active {
  color: #d95316;
}
