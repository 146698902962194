@import "../../../css/mixins/mixins";
@value orange from 'css/Colors.module.css';

.header {
  padding: 23px 0;
}

.header__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1090px;
  padding: 0 15px;
  margin: 0 auto;
  @include adaptive(mob) {
    padding: 15px 20px;
    /*margin-bottom: 23px;*/
    grid-gap: 15px;
    align-items: flex-start;
  }
}

.header__logo {
  width: 255px;
  height: 50px;
  background: url('./img/logo.svg') 0 0/100% auto no-repeat;
}

.header__auth-container {
  display: flex;
  align-items: center;
  grid-gap: 26px;
  @include adaptive(mob) {
    grid-gap: 25px;
    font-size: 14px;

    display: grid;
    grid-template-columns: auto auto;
    width: 100%;
    padding-top: 10px;
    align-items: flex-start;
  }
}

.header__widgets-container {
  display: flex;
  align-items: center;
  grid-gap: 26px;
  @include adaptive(mob) {
    grid-gap: 25px;

    grid-column: 1 / 3;
    grid-row: 2 / 3;
    justify-content: flex-end;
  }
}

.header__signIn {
  background-color: transparent;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  border: 1px solid #fe6520;
  border-radius: 8px;
  padding: 15px 26px;
  transition: background-color 0.3s, color 0.3s;
  cursor: pointer;
  min-width: 160px;
  text-align: center;
  color: #1a1a1a;
}

.header__signIn:hover {
  background-color: #fe6520;
  color: #ffffff;
}

.location {
  position: relative;
  @include adaptive(mob) {
    /*max-width: 100px;*/
  }
}

.location.disabled {
  pointer-events: none;
  opacity: .6;
}

.location__arrow {
  width: 24px;
  height: 24px;
  margin-top: 3px;
  background: url('./img/location-arrow.svg') 50% 50% no-repeat;
  transition: transform 0.2s linear;
}

.location__arrow_expanded {
  transform: rotate(180deg);
}

.location__default {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  cursor: pointer;
  transition: color 0.2s linear;
}

.location__default:hover {
  color: #fe6520;
}

.location__list {
  position: absolute;
  padding: 5px 0;
  min-width: 160px;
  top: 30px;
  right: 0;
  left: auto;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: #fcfcfc;
  border: solid 1px #ffdbbb;
  z-index: 1000;
  max-height: 550px;
  overflow-y: scroll;
}

.location__item {
  white-space: nowrap;
  padding: 8px 17px;
  line-height: 1.25;
  cursor: pointer;
  background-color: transparent;
  transition: background-color 0.2s;
  display: flex;
  grid-gap: 10px;
  align-items: flex-end;
}

.locationItemTimezone {
  font-size: 12px;
  color: #ff6620;
  margin-bottom: 1px;
}

.locationTimezone {
  font-size: 12px;
  color: #ff6620;
}

.location__item:hover {
  background-color: #ffe8d4;
}

@media (max-width: 900px) {
  .header {
    padding: 0;
  }

  .header__logo {
    width: 50px;
    background: url('./img/logo-mobile.svg') 50% 50%/100% auto no-repeat;
  }
}

@media (max-width: 600px) {
  .header__signIn {
    min-width: auto;
  }

}


.profileText {
  @include adaptive(mob) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  &__name {
    text-transform: capitalize;
    @include adaptive(mob) {
      text-align: right;
    }
  }
  &__balance {
    font-weight: 700;
  }
}