.avatar {
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 0;
    display: block;
    min-width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center top;
    object-position: center top;
    transform: translateX(-50%);
}

.avatar.noImg {
    display: flex;
    align-items: center;
    justify-content: center;
}

.initials {
    font-size: 32px;
    color: #ffffff;
}

.avatarWrapper {
    display: flex;
    width: 100px;
    height: 100px;
    border-radius: 100%;
    overflow: hidden;
    position: relative;
}

.noPhoto {
    background-color: #d0d0d0;
    display: block;
    height: 100%;
    left: 50%;
    min-width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center top;
    object-position: center top;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    z-index: 0;
}