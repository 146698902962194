.chat {
  /*width: 620px;*/
  width: 100%;
  margin: 47px auto 0;
}

.chat__body {
  max-height: 400px;
  overflow-y: auto;
  z-index: 1;
  position: relative;
  //display: flex;
  //flex-direction: column-reverse;
}
.chat__body::-webkit-scrollbar {
  width: 5px;
}
.chat__body::-webkit-scrollbar-track {
  background-color: #f0f0f0;
}
.chat__body::-webkit-scrollbar-thumb {
  height: 30%;
  background-color: #d0d0d0;
}

.chat__head {
  .chat__head__title {
    position: relative;
    font-size: 24px;
    color: #000;
    background-color: #ffffff;
    z-index: 1;

    &:after {
      position: absolute;
      top: 51%;
      overflow: hidden;
      width: 90.5%;
      height: 1px;
      content: '\a0';
      background-color: #ebebeb;
      margin-left: 3%;
    }
  }
}

.chat__head__unsubscribe {
  font-size: 12px;
  color: #8d9ab3;
  float: right;
  position: relative;
  top: 10px;
}

.chat__form {
  position: relative;
}

.chat__form .block_chat_item{pointer-events:none}

.chat__form .formControl {
  height: 67px;
  border-left: none;
  border-right: none;
  resize: none;
  border-bottom: 1px solid #ebebeb;
  border-top: 1px solid #ebebeb;
  font-weight: 300;
  overflow-y: hidden;
  padding: 23px 60px 23px 33px;
  width: 100%;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  font: 16px GolosText, Roboto, sans-serif;
  color: #000000;
  background-color: transparent;
}

.chat__form .formControl::-webkit-input-placeholder {
  color: #b6b9bd;
}

.chat__form .formControl:-moz-placeholder, .chat__form .formControl::-moz-placeholder {
  opacity: 1;
  color: #b6b9bd;
}

.chat__form .formControl:-ms-input-placeholder {
  color: #b6b9bd;
}

.chat__form button {
  position: absolute;
  right: 20px;
  //top: 50%;
  top: 34px;
  width: 24px;
  height: 26px;
  margin-top: -13px;
  background: url(./img/send.svg) no-repeat center;
  z-index: 10;
  cursor: pointer;
  border: 0;
}

.chat__body__date {
  font-size: 14px;
  line-height: 1.5;
  color: #9b9b9b;
  text-align: center;
  margin: 20px 0 19px;
}

.chat__body__message {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  margin-bottom: 40px;
}

.chat__body__message:last-child {
  margin-bottom: 35px;
}

.chat__body__message__photo {
  margin-right: 24px;
  position: relative;
  top: 5px;
}

.chat__body__message__body {
  position: relative;
}

.chat__body__message__text {
  -webkit-border-radius: 8px;
  border-radius: 8px;
  background-color: #f2f2f2;
  padding: 15px 17px 14px;
  font-size: 16px;
  line-height: 1.31;
  color: #383838;
  position: relative;
  max-width: 300px;
}

.chat__body__message__text:before {
  content: '';
  background: url(./img/caret-gray.svg);
  width: 37px;
  height: 41px;
  position: absolute;
  left: -20px;
  bottom: 5px;
}

.chat__body__message__time {
  position: absolute;
  top: 50%;
  left: 100%;
  margin-left: 19px;
  font-size: 12px;
  color: #cecece;
  white-space: nowrap;
  margin-top: -7px;
}

.chat__body__message.me {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.chat__body__message.me .chat__body__message__photo {
  -webkit-box-ordinal-group: 3;
  -webkit-order: 2;
  -ms-flex-order: 2;
  order: 2;
  margin-top: 0;
  margin-left: 28px;
}

.chat__body__message.me .chat__body__message__body {
  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1;
}

.chat__body__message.me .chat__body__message__text {
  background-color: #deffe0;
}

.chat__body__message.me .chat__body__message__text:before {
  background: url(./img/caret-green.svg);
  left: auto;
  right: -20px;
  bottom: 13px;
}

.chat__body__message.me .chat__body__message__time {
  left: auto;
  right: 100%;
  margin-left: 0;
  margin-right: 19px;
}

.avatar {
  margin-right: 0;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  min-width: 55px;
  min-height: 55px;
  background-color: #d6dde8;
  color: #fff;
  font-size: 18px;
  text-transform: uppercase;
  text-align: center;
  line-height: 55px;
  img{
    border-radius: 50%;
  }
}

.chat__boot {
  text-align: center;
  padding: 24px 0 0;
}

.chat__boot .button {
  display: inline-block;
  height: 36px;
  -webkit-border-radius: 22px;
  border-radius: 22px;
  background-color: #fff;
  border: solid 1px #dcdcdc;
  font-size: 14px;
  font-weight: 500;
  color: #8c8c8c;
  line-height: 34px;
  padding: 0 22px;
  transition: all .2s;
  font-family: GolosText,Roboto,sans-serif;
  outline: 0;
  width: auto;
  margin-bottom: 10px;
}

.chat__boot .button:hover {
  background-color: #dcdcdc;
  color: #fff;
  text-decoration: none;
}

.chat__boot__unsubscribe {
  font-size: 12px;
  color: #8d9ab3;
  text-align: center;
  margin-top: 18px;
}

.gameCard__member {
  flex: none;
  height: 55px;
  width: 55px;
  border-radius: 100%;
  text-decoration: none;
  color: #ffffff;
  text-align: center;
  background-size: cover;
}

.gameCard__member:global(.pink) {
  background-image: linear-gradient(to bottom, #f7a2cc, #e05be9);
}

.gameCard__member:global(.orange) {
  background-image: linear-gradient(to bottom, #ffe284, #ff8a5e);
}

.gameCard__member:global(.green) {
  background-image: linear-gradient(to bottom, #5af298, #32c9ab);
}

.gameCard__member:global(.lightgreen) {
  background-image: linear-gradient(to bottom, #b7eb89, #5ecb69);
}

.gameCard__member:global(.violet) {
  background-image: linear-gradient(to bottom, #dc8cfb, #8253f4);
}

.gameCard__member:global(.blue) {
  background-image: linear-gradient(to bottom, #56d6f3, #328bf2);
}

.gameCard__member:global(.lightblue) {
  background-image: linear-gradient(to bottom, #56e6f2, #33b4ee);
}

.gameCard__member:global(.red) {
  background-image: linear-gradient(to bottom, #ff9689 0%, #fe5773);
}

.gameCard__member:global(.white) {
  background-color: #ffffff;
  color: #1a1a1a;
}

.gameCard__memberName {
  letter-spacing: -2px;
  font-weight: 400;
  font-size: 18px;
  text-transform: uppercase;
  text-align: center;
  line-height: 55px;
}


@media screen and (max-width: 767px) {
  .chat {
    width: auto;
    padding: 0 16px;
    overflow: hidden;
    margin-bottom: 0 !important;
  }

  .chat__head {
    padding-bottom: 16px;
  }

  .chat__form .form-control {
    padding: 23px 60px 23px 15px;
  }

  .chat__body {
    padding-top: 27px;
    padding-right: 20px;
  }

  .chat__body__date {
    display: none;
  }

  .chat__body__message {
    margin-bottom: 30px;
  }

  .chat__body__message__body {
    padding: 14px 14px 13px;
    font-size: 13px;
    max-width: 200px;
    background-color: #f2f2f2;
    -webkit-border-radius: 8px;
    border-radius: 8px;
  }

  .chat__body__message__body:before {
    content: '';
    background: url(./img/caret-gray.svg);
    width: 37px;
    height: 41px;
    position: absolute;
    left: -20px;
    bottom: 0;
    -webkit-transform: scale(.5);
    -ms-transform: scale(.5);
    transform: scale(.5);
  }

  .chat__body__message__text {
    padding: 0;
    font-size: 13px;
    display: inline;
  }

  .chat__body__message__time {
    position: relative;
    float: right;
    margin: 0 !important;
    padding-top: 5px;
    padding-left: 8px;
    top: -2px;
    right: auto;
    left: auto;
  }

  .chat__body__message.me .chat__body__message__body {
    background-color: #deffe0;
  }

  .chat__body__message.me .chat__body__message__body:before {
    left: auto;
    right: -20px;
    background: url(./img/caret-green.svg);
    bottom: 0;
  }

  .chat__body__message.me .chat__body__message__time {
    right: auto;
    left: auto;
  }

  .chat__body__message__photo {
    margin-right: 15px;
    top: 0;
  }

  .chat__body__message.me .chat__body__message__photo {
    margin-right: 0;
    margin-left: 15px;
  }

  .chat__body__message__text:before {
    display: none;
  }

  .chat__boot {
    border-top: 0;
    padding: 0 0 24px;
    border-bottom: 1px solid #ebebeb;
  }
}