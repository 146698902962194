.footer {
  background-color: #f1f2f4;
  padding: 65px 0;
  border-top: 1px solid #c4c4c4;
  margin-top: auto;
}

.footer__content {
  display: flex;
  margin-bottom: 15px;
}

.footer__logo-container {
  flex: none;
  width: 25%;
}

.footer__logo {
  width: 100%;
  height: 36px;
  margin-bottom: 30px;
  background: url('./img/logo-footer.svg') no-repeat;
}

.footer__donate {
  display: inline-block;
  font-weight: bold;
  font-size: 14px;
  line-height: 13px;
  color: #ff6620;
  cursor: pointer;
  transition: opacity 0.2s linear;
}

.footer__donate:hover {
  opacity: 0.8;
}

.footer__links {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: none;
  width: 33%;
  padding: 5px;
}

.footer__link {
  font-size: 14px;
  color: #7e8691;
  margin-bottom: 10px;
  text-decoration: none;
  padding-bottom: 2px;
  /*border-bottom: 1px solid #dde3ea;*/
  transition: border-bottom-color 0.2s linear;
}

.footer__link:hover {
  color: #7e8691;
  text-decoration: none;
  border-bottom-color: #7e8691;
}

.footer__link:active {
  color: #7e8691;
  text-decoration: none;
  border-bottom-color: #dde3ea;
}

.footer__copyright {
  flex: none;
  width: 100%;
  line-height: 84px;
  text-align: right;
  color: #7e8691;
  font-size: 14px;
}

.footer__social-container {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  padding-left: 15px;
}

.footer__social-line {
  display: flex;
  flex-wrap: wrap;
  flex: none;
  width: 100%;
}

.footer__social {
  display: flex;
  flex: none;
  justify-content: space-between;
}

.footer__social-link {
  margin: 0 20px 15px 0;
}

.footer__vk {
  width: 36px;
  height: 36px;
  background-image: url(./img/vk-icon.svg);
}

.footer__inst {
  width: 36px;
  height: 36px;
  background-image: url(./img/inst-icon.svg);
}

.footer__fb {
  width: 36px;
  height: 36px;
  background-image: url(./img/fb-icon.svg);
}

.footer__mobile-app {
  display: flex;
  margin-right: -15px;
  flex: 1;
}

.footer__mobile-app a {
  margin-right: 15px;
}

.footer__gplay {
  width: 123px;
  height: 36px;
  background: url(./img/gplay-icon.svg) 50% 50% / contain no-repeat;
}

.footer__appstore {
  width: 123px;
  height: 36px;
  background: url(./img/appstore-icon.svg) 50% 50% / contain no-repeat;
}

@media (max-width: 900px) {
  .footer__logo-container {
    width: 100%;
    margin-bottom: 15px;
  }

  .footer__social-line {
    flex-wrap: wrap;
  }

  .footer__social {
    justify-content: center;
    margin-bottom: 20px;
  }

  .footer__content {
    flex-wrap: wrap;
  }

  .footer__mobile-app,
  .footer__social,
  .footer__links,
  .footer__logo {
    flex: none;
    width: 100%;
  }

  .footer__mobile-app {
    justify-content: center;
    margin: 0;
  }

  .footer__mobile-app a {
    margin: 0 10px;
  }

  .footer__logo {
    height: 36px;
    background-position: 50% 50%;
  }

  .footer__links {
    align-items: center;
    margin-bottom: 15px;
  }

  .footer__copyright,
  .footer__logo-container {
    text-align: center;
  }
}


.footer__fund {
  display: flex;
  margin-bottom: 80px;
}
.footer__fund-text {
  width: 25%;
  font-size: 14px;
  color: #7e8691;
  text-decoration: none;
  line-height: 150%;
  margin-top: 0;
  padding-right: 40px;
}
.footer__fund-text a {
  text-decoration: underline;
  font-size: 14px;
  color: #7e8691;
  font-weight: 500;
}
.footer__fund-img-wrapper {
  width: 33%;
}
.footer__fund-img-wrapper img {
  max-width: 180px;
}
@media (max-width: 900px) {
  .footer__fund {
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
  }
  .footer__fund-text {
    width: 100%;
    text-align: center;
    padding-right: 0;
    margin-bottom: 0;
  }
  .footer__fund-img-wrapper {
    width: 100%;
    margin-top: 40px;
    display: flex;
    justify-content: center;
  }
}