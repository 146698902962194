@import 'src/css/mixins/mixins';

.categories {
    padding-left: 16px;
    padding-right: 24px;
    @include adaptive(mob) {
        padding: 0 20px;
    }
}

.categoryOption {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.categoryOptionName {
}

.categoryOptionLabel {
    color: #838587;
}

.separator {
    height: 12px;
    margin-bottom: 12px;
    border-bottom: 1px solid #D6D6D7;
}

@media (min-width: 840px) {
    .categories {
        min-height: 500px;
        padding: 0 18px 40px 32px;
    }

    .categoryOption {
        height: 44px;
        width: 100%;
        padding: 12px 16px;
        border-radius: 8px;
        cursor: pointer;
        transition: background-color 0.2s;

        &.selected {
            background-color: #F1F2F4;
            cursor: default;
        }

        &:not(.selected):hover {
            background-color: rgba(255, 232, 212, 0.5);
        }
    }

    .categoryOptionName {
        font-weight: 500;
    }
}