@import "../../../../../../css/mixins/mixins";
@import "css/mixins/dots";

.notification {
    display: flex;
    flex-wrap: nowrap;
    padding: 12px 0 16px 0;
}

.column1 {
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-shrink: 0;
    flex-grow: 0;
    width: 20px;
}

.column2 {
    width: 100%;
    margin-left: 8px;
}

.headline {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    height: 20px;
    grid-gap: 10px;
}

.title {
    font-size: 14px;
    font-weight: 500;
    @include dots;
}

.date {
    font-size: 12px;
    color: #5A5C5F;
    flex-shrink: 0;
}

.dateString {
}

.timeString {
    display: none;
}

.text {
    margin-top: 4px;
    font-size: 14px;
    color: #5A5C5F;
    user-select: text;

    @include line-clamp(3);

    &.expanded {
        @include line-clamp(unset);
    }
}

.unreadIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;

    &::after {
        content: "";
        width: 6px;
        height: 6px;
        background: #FF6620;
        border-radius: 12px;
    }

    @keyframes slide-out {
        0% {
            transform: scale(1);
            opacity: 1;
        }
        100% {
            transform: scale(2);
            opacity: 0;
        }
    }

    &.slideOut {
        animation: slide-out 0.3s cubic-bezier(0.550, 0.085, 0.680, 0.530) forwards;
    }
}

@media (min-width: 768px) {
    .title {
        font-size: 16px;
    }

    .text {
        font-size: 16px;
    }

    .date {
        font-size: 14px;
    }
}

@media (min-width: 840px) {
    .notification {
        padding: 14px 12px 12px 14px;
        border-radius: 12px;
        cursor: pointer;
        transition: background-color 0.3s;

        &:not(:first-child) {
            margin-top: 4px;
        }

        &:hover {
            background-color: #F1F2F4;
        }
    }

    .column2 {
        width: 100%;
    }

    .text {
        max-width: 440px;
    }

    .timeString {
        display: inline;
    }
}

@media (min-width: 1440px) {
    .text {
        max-width: 560px;
    }
}