.item {
  flex: none;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  border: 2px solid #f1f2f4;
  text-decoration: none;
  color: #ffffff;
  text-align: center;
  background-size: cover;
  position: relative;
  overflow: hidden;
  &:hover {
    filter: brightness(.85);
  }
  &.pink {
    background-image: linear-gradient(to bottom, #f7a2cc, #e05be9);
  }

  &.orange {
    background-image: linear-gradient(to bottom, #ffe284, #ff8a5e);
  }

  &.green {
    background-image: linear-gradient(to bottom, #5af298, #32c9ab);
  }

  &.lightgreen {
    background-image: linear-gradient(to bottom, #b7eb89, #5ecb69);
  }

  &.violet {
    background-image: linear-gradient(to bottom, #dc8cfb, #8253f4);
  }

  &.blue {
    background-image: linear-gradient(to bottom, #56d6f3, #328bf2);
  }

  &.lightblue {
    background-image: linear-gradient(to bottom, #56e6f2, #33b4ee);
  }

  &.red {
    background-image: linear-gradient(to bottom, #ff9689 0%, #fe5773);
  }
  &.withPhoto {
    background-color: #cccccc;
  }
}

.bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-size: cover;
}

.name {
  letter-spacing: -2px;
  line-height: 36px;
  font-weight: 400;
  text-transform: uppercase;
  position: relative;
  z-index: 1;
}