.pageButton {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  flex-grow: 0;
  width: 28px;
  height: 28px;
  background-color: #F1F2F4;
  border-radius: 4px;
  font-size: 16px;
  color: #303337;
  cursor: pointer;

  &:not(:first-child) {
    margin-left: 16px;
  }

  &.selected {
    background-color: #FF6620;
    color: white;
  }
}

@media (min-width: 840px) {
  .pageButton {
    &:not(.selected) {
      &:hover {
        background-color: #e3e3e5;
      }
    }
  }
}