.coach {
  border: solid 1px #e2e2e2;
  border-radius: 8px;
  grid-gap: 10px;
  padding: 10px 15px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  &__imgWrapper {
    border-radius: 100%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border: solid 1px #e2e2e2;
    font-size: 12px;
  }
}