@import 'src/css/mixins/mixins';

.section {
  display: flex;
  grid-gap: 60px;
  @include adaptive(mob) {
    flex-direction: column;
    grid-gap: 30px;
  }
  p {
    line-height: 150%;
  }
}

.col1 {
  width: 340px;
  flex-shrink: 0;
  @include adaptive(mob) {
    width: 100%;
  }
}