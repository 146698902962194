@import 'src/css/mixins/mixins';

.container {
  width: 100%;
}

.header {
  font-size: 32px;
  font-weight: 500;
  border-bottom: 3px solid #F7F7F7;
  padding-bottom: 20px;
  @include adaptive(mob) {
    padding-bottom: 10px;
    font-size: 28px;
  }
}

.content {
  margin-top: 48px;
  padding-bottom: 48px;
  border-bottom: 3px solid #F7F7F7;
  @include adaptive(mob) {
    margin-top: 30px;
  }

  h1 {
    font-size: 24px;
    padding: 0;
    margin: 0;
    font-weight: normal;

    &:not(:first-child) {
      margin-top: 45px;
      @include adaptive(mob) {
        margin-top: 30px;
      }
    }
  }

  p {
    font-size: 14px;
    padding-left: 30px;
  }
}