.area-slider {
  margin: -42px -10px 33px;
  padding: 40px 10px;
}

.area-slider__link {
  display: flex;
}

.area-slider__img {
  display: block;
  width: 102px;
  height: 102px;
  margin: 30px auto;
  border: 1px solid #dedede;
  border-radius: 50px;
}
.area-slider__img:hover {
   filter: brightness(.85);
}

.area-slider .swiper-button-prev:after,
.area-slider .swiper-button-next:after {
  font-size: 0;
}

.area-slider .swiper-button-prev {
  background: url('./img/arrow-slider-icon.svg') 50% 50% no-repeat;
}

.area-slider .swiper-button-next {
  transform: rotate(180deg);
  background: url('./img/arrow-slider-icon.svg') 50% 50% no-repeat;
}

.area-slider .swiper-pagination-bullet {
  width: 6px;
  height: 6px;
}

.area-slider .swiper-pagination-bullet-active {
  background: #ff6620;
}

@media (max-width: 900px) {
  .area-slider .swiper-button-prev,
  .area-slider .swiper-button-next {
    display: none;
  }
}
