.button {
  width: 100%;
  font-weight: 500;
  color: #ffffff;
  padding: 17px 32px;
  background: #ff6620;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.2s linear;
}

.button_main-page {
  width: auto;
  display: inline-block;
}

.button:hover {
  background-color: #ff854d;
}

.button:active {
  background-color: #d95316;
}

.button:disabled {
  background-color: #acb6c3;
  cursor: default;
}

@media (max-width: 900px) {
  .button_main-page {
    width: 100%;
  }
}
