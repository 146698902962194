@mixin grid {
  display: grid;
  --grid-column-width: calc((100% / var(--cols)) - ((var(--gap) * (var(--cols) - 1) ) / var(--cols)) );
  grid-template-columns: repeat(var(--cols), var(--grid-column-width) );
  grid-gap: var(--gap);
}

/*
Надо обязательно указать переменную --gap и --cols. Пример использования:
--gap: 20px;
--cols: 3;
*/