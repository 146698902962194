@import 'src/css/mixins/mixins';

.infoBlock{
    display: flex;
    margin-bottom: 30px;
}

.infoBlockColumn{
    composes: infoBlock;
    flex-direction: column;
    gap: 16px;
}

.infoBlockTitle{
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #000;
    min-width: 150px;
    display: inline-block;
}

.infoBlockText{
    margin-bottom: 4px;
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #000;
}

.infoEmail{
    margin: -10px 0 5px;
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #000;
}

.changeEmail{
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #fa4824;
    cursor: pointer;
}

.voucherDiscount{
    height: auto;
    background: 0 0;
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    position: relative;
    margin: 8px 0 8px 0px;
    color: #40c173;
}

.summDiscount {
    margin-top: -10px;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #000;
}

.summDiscount strong {
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #000;
}


.addInfo{
}

.addInfoTitle{
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.43;
        letter-spacing: normal;
        color: #000;
        margin-bottom: 16px;
        display: block;
}

.addInfoText{
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #000;
}

.formInput{
    margin-bottom: 0;
}

.hidden{
    display: none;
}

.buttonGap{
    margin-bottom: 15px;
}

.container {
    width: 100%;
    max-width: 330px;
    padding-bottom: 60px;
    @include adaptive(mob) {
        max-width: none;
    }
}