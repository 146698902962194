.select {
    composes: select from 'components/UI/Select/Select.module.css';
    width: 95px;
}

.dropdown {
    composes: dropdown from 'components/UI/Select/Select.module.css';
    left: auto;
}

.control {
    composes: control from 'components/UI/Select/Select.module.css';
    height: auto;
    width: auto;
    color: #fa4824;
    border-bottom: none;
    padding: 0;
}

.toggleIcon {
    composes: toggleIcon from 'components/UI/Select/Select.module.css';
    display: none;
}
