@import 'src/css/mixins/mixins';

.swiper {
    width: 100%;
    height: 100%;
}

.swiperSlide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.swiperSlide img {
    display: block;
    width: 100%;
    max-height: 450px;
    object-fit: cover;
}

.headContent {
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
    height: 450px;
    position: relative;
    overflow: hidden;


}

.headInfo {
    background: rgba(0, 0, 0, .6);
    width: 100%;
    height: 75px;
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    color: #fff;
    padding: 0 20px;
    z-index: 2;
}

.headInfoTitle {
    width: 100%;
    max-width: 940px;
    margin: 0 auto;
}

.headInfoTitle > h1 {
    font-size: 30px;
    line-height: normal;
    font-weight: 500;
}

:root {
    --swiper-theme-color: #ffffff;
}



.infoContent {
    display: flex;
    align-items: flex-start;
    position: relative;
    margin: 20px 0;
    grid-gap: 25px;
    padding-right: calc(300px + 40px);

    @include adaptive(mob) {
        flex-direction: column;
        padding-right: 0;
        grid-gap: 40px;
    }
    &.noAdvantages {
        max-width: none;
    }
    .infoLogo {
        width: 110px;
        height: 110px;
        border: 1px #ebebeb solid;
        position: relative;
        flex-shrink: 0;

        @include adaptive(mob) {
            position: absolute;
            left: 0;
            top: 0;
        }

        .img {
            @media screen and (max-width: 430px) {
                max-width: 60px;
            }
        }
    }

    .infoDesc {
        width: 100%;

        &__titles {
            @include adaptive(mob) {
                min-height: 110px;
            }
        }

        &__title {
            font-size: 16px;
            line-height: normal;
            color: #000000;
            @include adaptive(mob) {
                margin-left: 130px;
            }
        }

        &__subtitle {
            margin-bottom: 20px;

            a {
                font-size: 13px;
                line-height: 1.77;
                text-align: right;
                color: #949494;
                text-decoration: underline;
            }

            @include adaptive(mob) {
                margin-left: 130px;
            }
        }

        &__kinds {
            display: flex;
            flex-wrap: wrap;
            grid-gap: 30px;

            @include adaptive(mob) {
                margin-top: 20px;
            }

            .kindItem {
                display: flex;
                align-items: baseline;
                flex-direction: column;
                flex-wrap: wrap;
                /*width: 70px;*/

                &__icon {
                    width: 30px;

                    img {
                        width: 100%;
                    }
                }

                &__title {
                    font-size: 13px;
                    font-weight: 700;
                    line-height: 1.38;
                    color: #1a1a1a;
                }

                &__info {
                    font-size: 13px;
                    line-height: 1.38;
                    color: #1a1a1a;
                }

            }
        }

        @media screen and (max-width: 480px) {
            max-width: none;
        }
    }
}


.container__games__arena {
    background: #ffffff;
    width: 100%;
    padding: 0 20px;
}

.container__content__arena {
    background-color: rgba(243, 243, 243, 0.58);
    border-top: 1px solid #ececec;
    border-bottom: 1px solid #ececec;
    @include adaptive(mob) {
        background-color: transparent;
    }
}

.games {
    padding: 40px 0;
    border-bottom: 1px solid #ececec;
    display: flex;
    flex-direction: column;
    grid-gap: 40px;

    &__head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        font-size: 32px;

        &__title {
            font-size: 32px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.25;
            letter-spacing: normal;
            color: #000000;
            @media screen and (max-width: 479px) {
                font-size: 25px;
            }
        }

        &__more {
            a {
                font-size: 14px;
                font-weight: 700;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: right;
                color: #fd5e21;
            }

            @media screen and (max-width: 479px) {
                display: none;
            }
        }

       &__cards__items {

           &__games{
               display: flex;
               flex-wrap: wrap;
               grid-gap: 20px;
               gap: 20px;
           }

           &__profile {
               margin-top: 0;
               padding-bottom: 53px;
               justify-content: start;
               &__empty {
                   padding-bottom: 13px;
                   line-height: 1.4;
                   color: #707070;
                   font-size: 15px;
                   font-weight: 300;
               }
           }
        }
    }
}

.mapArena {
    padding: 20px 0;

    &__title {
        font-size: 18px;
        font-weight: 900;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.33;
        letter-spacing: normal;
        color: #484848;
        margin-bottom: 20px;
    }

    &__place,
    &__road {
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #000000;
    }

    &__place {
        margin-bottom: 10px;
    }

    &__road {
        margin-bottom: 20px;
    }
}



.contentArena {
    display: flex;
    position: relative;
    padding: 20px 0;

    @include adaptive(mob) {
        padding: 0;
        flex-direction: column-reverse;
        grid-gap: 20px;
        margin-top: 20px;
        padding-bottom: 20px;
    }

    &__desc {
        max-width: calc(100% - 300px - 40px);

        @include adaptive(mob) {
            max-width: none;
        }
        &.noAdvantages {
            max-width: none;
        }

        p {
            font-size: 16px;
            line-height: 1.38;
            color: #484848;
        }

        p:first-child {
            margin-top: 0;
        }

        p:last-child {
            margin-bottom: 0;
        }

    }

    &__descText {
        margin-bottom: 20px;
    }

    &__date {
        &__title {
            font-size: 18px;
            font-weight: 900;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.33;
            letter-spacing: normal;
            color: #484848;
            margin-bottom: 10px;
        }

        &__time {
            font-size: 16px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.38;
            letter-spacing: normal;
            color: #484848;
            margin-bottom: 10px;
        }

        &__feedback {
            margin-bottom: 40px;

            a {
                font-size: 14px;
                font-weight: 700;
                line-height: normal;
                color: #fd5e21;
                text-decoration: underline;
            }
        }

        &__btn {
                width: 100%;
                max-width: 240px;
                border-radius: 4px;
                background-color: #fa4824;
                text-transform: uppercase;
                font-size: 14px;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.57;
                letter-spacing: 0.9px;
                text-align: center;
                cursor: pointer;
                padding: 15px 50px;
                transition: all 0.3s ease;

                color: #ffffff;
                text-decoration: none;
                font-weight: bold;
                * {
                    color: #ffffff;
                    text-decoration: none;
                    font-weight: bold;
                }

                &:hover {
                    background: #d33617;
                }

                &:active {
                    background: #b72f14
                }
            &.mw-280 {
                max-width: 280px;
            }

        }
    }

    &Conditions {
        position: absolute;
        top: 0;
        right: 0;
        width: 300px;
        padding: 30px;
        border-radius: 8px;
        box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, 0.1);
        background-color: #ffffff;
        border: 1px #ececec solid;
        z-index: 1;

        &__title {
            font-size: 18px;
            font-weight: 900;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.33;
            letter-spacing: normal;
            color: #484848;
            margin-bottom: 30px;
            @include adaptive(mob) {
                margin-bottom: 20px;
            }
        }

        &__list {
            .list {
                padding: 0;
                margin: 0;
                list-style: none;

                &__item {
                    font-size: 16px;
                    font-weight: normal;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 1.5;
                    letter-spacing: normal;
                    color: #484848;
                    display: flex;
                    align-items: center;
                    margin-bottom: 20px;

                    &.heated:before {
                        content: '';
                        background: url('./img/heated.png') no-repeat;
                        background-size: contain;
                    }

                    &.shower:before {
                        content: '';
                        background: url('./img/shower.png') no-repeat;
                        background-size: contain;
                    }

                    &.rent:before {
                        content: '';
                        background: url('./img/rent.png') no-repeat;
                        background-size: contain;
                    }

                    &.dress:before {
                        content: '';
                        background: url('./img/dress.png') no-repeat;
                        background-size: contain;
                    }

                    &.water:before {
                        content: '';
                        background: url('./img/water.png') no-repeat;
                        background-size: contain;
                    }

                    &.cafe:before {
                        content: '';
                        background: url('./img/cafe.png') no-repeat;
                        background-size: contain;
                    }

                    &.parking:before {
                        content: '';
                        background: url('./img/parking.png') no-repeat;
                        background-size: contain;
                    }

                    &.sauna:before {
                        content: '';
                        background: url('./img/sauna.png') no-repeat;
                        background-size: contain;
                    }

                    &.ultraviolet:before {
                        content: '';
                        background: url('./img/ultraviolet.png') no-repeat;
                        background-size: contain;
                    }

                    &:before {
                        width: 15px;
                        height: 20px;
                        margin-right: 15px;
                        margin-top: 3px;
                    }
                }
            }
        }

        @include adaptive(mob) {
            border: none;
            position: static;
            box-shadow: none;
            padding: 0;
        }
    }

}

.swiper {
    height: 450px;
}

.buttonWrapper {
    margin-top: 20px;
}

.button {
    width: auto;
}

.map {
    margin-top: 30px;
}