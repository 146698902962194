.vkPopupContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 24px 53px;

  position: sticky;
  width: 100%;
  height: 112px;
  bottom: 0;
  margin: 0 auto;
  background: #1E4BD2;
  border-radius: 16px 16px 0px 0px;

  z-index: 10000;
}

.text {
  color: #FFFFFF;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;

  height: 64px;
  max-width: 500px;

  flex: none;
  order: 0;
  flex-grow: 0;
  margin-left: 20px;
}

.orangeText {
  color: #F1A037
}

.buttonGoTo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 32px;

  width: 251px;
  height: 52px;

  background: #FF6620;
  border-radius: 8px;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #FFFFFF;

  cursor: pointer;

  &:hover {
    background: #ff854d;
  }

}

.buttonClose {
  margin-left: 25px;
  cursor: pointer;
}

.buttonIcon {
  padding-left: 8px;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media(min-width: 1064px) {
  .vkPopupContainer {
    width: 1064px;
  }
}

@media (max-width: 900px) {
  .vkPopupContainer {
    position: fixed;
    /*height: 100vh;*/
    height: 100%;
    border-radius: unset;
    background: url("bg@2x.png") no-repeat right/100% #1E4BD2;

    @media (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi) {
      background-image: url("bg@4x.png");
    }
  }

  .text{
    position: absolute;
    top: 44px;
    left: 20px;
    width: 263px;
    height: 74px;
    font-size: 18px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0em;
  }

  .buttonClose {
    position: absolute;
    right: 21px;
    top: 21px;
  }

  .buttonGoTo {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 32px;
  }

  :global(html.vkPopupIsOpen) {
    overflow: hidden;
  }
}