@import "../../../../css/mixins/mixins";
@value text from '/src/components/UI/Filter/MobButton/FilterMobButton.module.scss';
@value activeFiltersCount from '/src/components/UI/Filter/MobButton/FilterMobButton.module.scss';

.pageTitle {
  font-size: 24px;
}

.pageHeader {
  border-bottom: 3px #f7f7f7 solid;
  padding-bottom: 15px;
}

.monthSwitcher {
  margin-top: 20px;
}

.searchResults {
  flex: 1;
}

.mainSection {
  padding-top: 25px;
}

.mainContainer {
  display: flex;
  grid-gap: 20px;
}

.filter {
  @include adaptive(mw1020) {
    display: none;
  }
}

.yearsRow {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.filterMob {
  flex-direction: row-reverse;
  margin-top: -2px;
  margin-right: 10px;
  @include adaptive(not-mob) {
    display: none !important;
  }
  .text {
    font-size: 16px;
  }
  .activeFiltersCount {
    right: -15px;
    top: -15px;
  }
}