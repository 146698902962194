@value gray from 'css/Colors.module.css';
@value orange from 'css/Colors.module.css';
@import "../../../../../css/mixins/mixins";

.container {
  padding: 30px 20px;
  width: 340px;
  background-color: gray;
  border-radius: 8px;
  position: sticky;
  top: 20px;
  @include adaptive(mob) {
    padding: 0;
    width: calc(100% - 30px);
    background-color: initial;
    position: fixed;
    top: auto;
    bottom: 10px;
    z-index: 999;
  }
  @include adaptive(mob-landscape) {
    padding: 20px 15px;
  }
}

.list {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  grid-gap: 30px;
  padding: 0;
  padding-left: 5px;
  margin-bottom: 60px;
  @include adaptive(mob) {
    display: none;
  }
  @include adaptive(mob-landscape) {
    grid-gap: 20px;
    margin-bottom: 30px;
  }
}

.item {
  font-size: 16px;
  padding-left: 20px;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  @include adaptive(mob-landscape) {
    padding-left: 15px;
  }
  &.active {
    font-weight: 700;
    &:after {
      opacity: 1;
    }
  }
  &:hover {
    font-weight: 700;
    &:after {
      opacity: 1;
    }
  }
  &:after {
    content: '';
    display: block;
    width: 2px;
    height: calc(100% + 10px);
    background-color: orange;
    position: absolute;
    left: 0;
    top: -5px;
    opacity: 0;
  }
}