.fullscreenButton {
    background-color: #ff854b !important;
    transform: scale(1.3) !important;
}

.fullscreenButton:hover {
    background-color: #FF6620 !important;
}

.fullscreenButton ymaps {
    filter: brightness(0) invert(1) !important;
}