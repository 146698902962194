.date-filter {
  display: flex;
  /*margin-bottom: 15px;*/
  max-width: 100%;
  overflow-x: auto;
  grid-gap: 40px;
  padding-bottom: 15px;
}

.date-filter::-webkit-scrollbar {
   width: 2px;
   height: 2px;
 }
.date-filter::-webkit-scrollbar-track {
   background-color: #f0f0f0;
 }
.date-filter::-webkit-scrollbar-thumb {
   height: 30%;
   background-color: #d0d0d0;
 }

.date-filter__item {
  display: flex;
  flex-direction: column;
  margin-right: 40px;
  user-select: none;
  cursor: pointer;
}

.date-filter__week-day {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.29;
  color: #949494;
  transition: color 0.2s;
}

.date-filter__date {
  padding-bottom: 5px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.38;
  color: #1a1a1a;
  margin: 10px 0;
  border-bottom: 2px solid #fff;
  transition: color, border-color 0.2s;
}

.date-filter__item:hover *,
.date-filter__item_active * {
  color: #fa4824;
  border-color: #fa4824;
}
