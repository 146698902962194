@import "../../../css/mixins/mixins";

.userBlock{
    display: flex;
    gap: 12px;
    align-items: center;
    font-size: 16px;
    cursor: pointer;
    @include adaptive(mob) {
        justify-content: flex-end;
    }
}

.avatar{
    width: 48px;
    height: 48px;
    border-radius: 24px;
}

.avatar.noImg {
    display: flex;
    align-items: center;
    justify-content: center;
}

.initials {
    font-size: 16px;
    color: #ffffff;
}


@media (max-width: 900px) {
    .avatarWrapper{
        display: none !important;
    }
}