@import "../../../../css/mixins/mixins";
@value gray from 'css/Colors.module.css';
@value orange from 'css/Colors.module.css';

.page {
  padding-bottom: 60px;
  * {
    white-space: break-spaces;
  }
}

.container {
  display: grid;
  grid-template-columns: calc(100% - 360px) 340px;
  grid-gap: 20px;
  margin-top: 25px;
  align-items: flex-start;
  @include adaptive(mob) {
    display: block;
  }
}

.content {
  flex: 1;
}

.sectPhotos {
  width: 100%;
  height: 300px;
  background-color: gray;
}

.mainTitle {
  font-size: 32px;
  margin: 0;
}

.sectTitle {
  font-size: 24px;
  margin: 0;
  margin-bottom: 20px;
  padding: 0;
}

.locationShort {
  color: #838587;
  margin-top: 10px;
}

.sports {
  font-weight: 500;
  margin-top: 20px;
}

.sect {
  &:not(:first-child) {
    padding-top: 50px;
    @include adaptive(mob) {
      padding-top: 30px;
    }
  }
}

.sectMain {
    &__topSide {
      border-bottom: 3px gray solid;
      padding-bottom: 30px;
    }
  &__bottomSide {
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    grid-gap: 30px;
  }
}

.datesBlock {
  background-color: gray;
  border-radius: 8px;
  padding: 25px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  @include adaptive(mob) {
    display: block;
  }
  &__item {
    @include adaptive(mob) {
      &:not(:first-child) {
        margin-top: 20px;
      }
    }
  }
  &__itemTitle {
    font-weight: 700;
  }
  &__itemValue {
    font-size: 18px;
    margin-top: 10px;
  }
}

.accommodations {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
}

.accommodation {
  display: flex;
  grid-gap: 20px;
  @include adaptive(mob) {
    flex-direction: column;
    grid-gap: 20px;
  }
  &__content {

  }
  &__item {
    &:not(:first-child) {
      margin-top: 20px;
    }
  }
  &__itemTitle {
    font-size: 18px;
    font-weight: 500;
  }
  &__itemDesc {
    margin-top: 10px;
  }
}

.photo {
  width: 250px;
  height: 335px;
  position: relative;
  flex-shrink: 0;
  @include adaptive(mob) {
    width: 100%;
  }
}

.list {
  list-style-type: none;
  padding: 0;
  &__item {
    display: flex;
    align-items: center;
    &:not(:first-child) {
      margin-top: 10px;
    }
    &:before {
      content: '';
      width: 4px;
      height: 4px;
      background-color: orange;
      display: block;
      margin-right: 10px;
    }
  }
}

.schedule {
  &:not(:first-child) {
    margin-top: 20px;
  }
  &__date {
    font-size: 18px;
    font-weight: 500;
  }
  &__desc {
    margin-top: 8px;
  }
}

.mainSlider {
  height: 400px;
  background-color: gray;
  @include adaptive(mob-landscape) {
    height: 90vh;
  }
  &__slide {
    position: relative;
  }
  &__slideImg {

  }
}

.map {
  margin-top: 20px;
}