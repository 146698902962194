.support-form {
  width: 100%;
  max-width: 650px;
  display: flex;
  flex-direction: column;
  margin: 50px 0 75px;
}

.content-page {
  margin: 100px auto 175px;
}
