@use "sass:math";
@import "../../../css/mixins/mixins";

$mainImg_width: 720;
$mainImg_height: 467;

$ratio: math.div($mainImg_height, $mainImg_width);
$ratioProcent_vw: ($ratio * 100) + vw;
$mainImgOffsetMob: 80px;

.all-games {
  padding-top: 40px;
  padding-bottom: 60px;
  overflow-x: clip;
}

.all-games .wrap {
  position: relative;
}

.all-games-img {
  background-image: url(./img/sploteam-games-bg.png);
  width: $mainImg_width + px;
  height: $mainImg_height + px;
  background-size: cover;
  position: absolute;
  right: -240px;
  bottom: -115px;
  @include adaptive(mob) {
    position: static;
    background-image: url(./img/sploteam-games-bg.png);
    width: calc(100vw + ($mainImgOffsetMob * 2));
    height: calc($ratioProcent_vw + ($mainImgOffsetMob * 2 * $ratio));
    right: auto;
    margin-left: -$mainImgOffsetMob;
    margin-top: 30px;
  }
}

.campButton {
  margin-top: 30px;
  @include adaptive(mob) {
    margin-top: 15px;
    display: flex !important;
    justify-content: center;
  }
}

@media (min-width: 900px) {
  @media (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi) {
    .all-games-img {
      background-image: url(./img/sploteam-games-bg@2x.png);
    }
  }
}

.games-list {
  display: flex;
  flex-wrap: wrap;
  margin: 50px 0 0 -25px;
}

.games-list__item {
  position: relative;
  flex: none;
  height: 120px;
  width: 110px;
  text-align: center;
  padding: 70px 0 0 0;
  margin: 0 15px;
  font-weight: 700;
  color: #2b313b;
  text-decoration: none;
  background-size: 54px auto;
  background-position: 50% 0, 50% 5%;
  background-repeat: no-repeat, no-repeat;
  top: 0;
  transition: top 0.2s;
}

.games-list__item:hover {
  top: -5px;
}

.games-list__sm {
  font-size: 12px;
  font-weight: 500;
  margin-top: 5px;
}

.games-list__item_volleyball {
  background-image: url(img/volleyball-icon@1x.png), url(./img/icon-shadow.png);
}

.games-list__item_football {
  background-image: url(img/football-icon@1x.png), url(./img/icon-shadow.png);
}

.games-list__item_basketball {
  background-image: url(img/basketball-icon@1x.png), url(./img/icon-shadow.png);
}

.games-list__item_more-games {
  background-image: url(./img/more-icon.svg);
}

@media (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi) {
  .games-list__item_volleyball {
    background-image: url(img/volleyball-icon@2x.png),
      url(./img/icon-shadow.png);
  }

  .games-list__item_football {
    background-image: url(img/football-icon@2x.png), url(./img/icon-shadow.png);
  }

  .games-list__item_basketball {
    background-image: url(img/basketball-icon@2x.png),
      url(./img/icon-shadow.png);
  }
}

.coming-games__list {
  margin: 40px 0;
  @include adaptive(mob) {
    --cols: 1 !important;
  }
}

.coming-games {
  background-color: #f3f7fa;
  padding-bottom: 65px;
  padding-top: 65px;
}

.coming-games__title {
  margin: 0;
}

.coming-games__text {
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  margin-top: 18px;
}

.create-game {
  background-color: #fff;
  padding: 50px 0 45px 0;
}

.create-game__list {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 800px;
  margin: 40px auto 55px;
}

.create-game__item {
  text-align: center;
  width: 200px;
  font-weight: 500;
  margin: 0 auto;
}

.create-game__img {
  position: relative;
  border-radius: 100%;
  width: 120px;
  height: 120px;
  margin: 0 auto 38px;
}

.create-game__ball {
  background: #f1f2f4 url(./img/icnon_voll.svg) 50% 50% no-repeat;
}

.create-game__donate {
  background: #f1f2f4 url(./img/icon_donate.svg) 50% 50% no-repeat;
}

.create-game__team {
  background: #f1f2f4 url(./img/icon_team.svg) 50% 50% no-repeat;
}

.create-game__counter {
  position: absolute;
  width: 26px;
  height: 26px;
  bottom: -13px;
  right: 50%;
  margin-right: -13px;
  color: #fff;
  font-size: 15px;
  line-height: 26px;
  font-weight: 700;
  text-align: center;
  background-color: #ff6620;
  border-radius: 100%;
  font-family: Roboto, sans-serif;
}

.rent-area {
  background-color: #fff;
  padding: 50px 0 0;
  margin-bottom: -85px;
  padding-bottom: 160px;
}

.cooperation {
  padding: 60px 0 65px 0;
  background-color: #f1f2f4;
  overflow-x: clip;
  .wrap {
    position: relative;
  }
}

.cooperationImg {
  position: absolute;
  right: -320px;
  top: -180px;
  width: 750px;
  @include adaptive(mob) {
    display: none;
  }
}

.cooperation__title {
  line-height: 1.3;
  margin-bottom: 30px;
}

.cooperation__text {
  font-size: 16px;
  font-weight: 500;
  max-width: 400px;
  margin-bottom: 35px;
}

.g-line {
  border-bottom: 1px solid #dce0eb;
  margin: 18px 0 10px;
}

::-webkit-input-placeholder {
  color: #7e8691;
}
::-moz-placeholder {
  color: #7e8691;
}
:-moz-placeholder {
  color: #7e8691;
}
:-ms-input-placeholder {
  color: #7e8691;
}

:focus::-webkit-input-placeholder {
  color: transparent;
}
:focus::-moz-placeholder {
  color: transparent;
}
:focus:-moz-placeholder {
  color: transparent;
}
:focus:-ms-input-placeholder {
  color: transparent;
}

.cooperation-form {
  display: flex;
  margin: 0 -10px;
}

.cooperation-form__field {
  flex: none;
  width: 100%;
  max-width: 250px;
  margin: 0 10px;
}

.cooperation-form__field input {
  background-color: #ffffff;
}

.cooperation-form__submit {
  width: auto;
  margin: 0 10px;
}

@media (max-width: 900px) {
  .form-cooperation,
  .coming-games__list {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }

  .coming-games__list {
    margin: 48px 0;
  }

  .create-game__list {
    width: 100%;
  }

  .create-game__item {
    margin: 0;
  }

  .rent-area {
    margin-bottom: 65px;
  }

  .cooperation {
    padding-top: 40px;
    background-image: none;
  }

  .cooperation__title {
    text-align: center;
  }

  .cooperation__text {
    text-align: center;
    margin: 0 auto 32px;
  }

  .cooperation-form {
    flex-wrap: wrap;
    margin: 0;
  }

  .cooperation-form__field {
    max-width: 100%;
    margin: 0 0 20px;
  }

  .cooperation-form__submit {
    width: 100%;
    margin: 0;
  }

  .all-games__title {
    text-align: center;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
  }

  .all-games__title br {
    display: none;
  }

  .all-games {
    min-height: auto;
    background: none;
    padding-bottom: 28px;
  }

  .all-games__cover-mobile-show {
    width: calc(100% + 40px);
    position: relative;
    left: -20px;
    right: -20px;
  }

  .all-games__cover-mobile-show:after {
    display: block;
    content: '';
    height: 0;
    padding-bottom: 83%;
  }

  .all-games__cover-img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: url(./img/sploteam-games-bg-mobile@1x.png) 50% 50%/100% auto
      no-repeat;
  }

  @media (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi) {
    .all-games__cover-img {
      background: url(./img/sploteam-games-bg-mobile@2x.png) 50% 50%/100% auto
        no-repeat;
    }
  }

  .games-list {
    margin: 50px auto 0;
    width: 100%;
    justify-content: center;
  }

  .coming-games {
    padding-top: 53px;
  }

  .create-game {
    padding-top: 35px;
  }
}

@media (max-width: 600px) {
  .games-list {
    justify-content: center;
  }

  .games-list__item {
    margin-bottom: 25px;
  }

  .all-games__title {
    font-size: 28px;
  }

  .coming-games__title {
    margin-bottom: 25px;
  }

  .create-game__list {
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
  }

  .create-game__item {
    margin-bottom: 50px;
  }

  .create-game__item:last-child {
    margin-bottom: 0;
  }
}
