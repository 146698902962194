.avatarModal {
  width: 100%;
  max-width: 480px;
  padding: 25px;
}

.avatarCropPreview,
.avatarSelectTitle {
  display: flex;
}

.avatarCropPreview,
.avatarCropContainer,
.avatarSelectTitle,
.avatarSelectInput {
  border-bottom: 1px solid #ff6620;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

:global(.cropPreviewImg) {
  width: 100px;
  height: 100px;
  border-radius: 100px;
  margin: 0 auto;
  overflow: hidden;
  border: 1px solid #ff6620;
}

.avatarCropHelpText {
  flex: 1;
  color: #ff6620;
  margin-left: 25px;
}

.avatar-crop-container :global(.cropperBg),
.avatar-crop-container :global(.cropperModal) {
  background: none;
}

.avatarBtnGroup {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  flex-direction: column;
}

.avatarSelectTitle {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
}

.avatarSelectText {
  display: flex;
  color: #ff6620;
  margin-bottom: 15px;
}

/*.cropPreviewImg{*/
/*  width: 100px !important;*/
/*  height: 100px !important;*/
/*  overflow: hidden;*/
/*}*/

/*.crop-preview-img > img {*/
/*  width: 100px !important;*/
/*  height: 100px !important;*/
/*  border-radius: 50px;*/
/*  margin: 0 auto;*/
/*  position: relative;*/
/*  top: 0;*/
/*  left: 0;*/
/*}*/