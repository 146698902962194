@value orange from 'css/Colors.module.css';
@import "../../../css/mixins/mixins";

.select {
  width: 100%;
  display: inline-flex;
  position: relative;
  background-color: #F1F2F4;
  border-radius: 8px;
  @include adaptive(mob) {
    border: 1px #d7d7d7 solid;
  }
}
.select.disabled {
  cursor: default;
  pointer-events: none;
  opacity: .4;
}

.select.expanded {
  border-radius: 8px 8px 0 0;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.dropdown {
  position: absolute;
  min-width: 210px;
  max-height: 250px;
  overflow-y: auto;
  right: 0;
  left: 0;
  top: calc(100% - 2px);
  text-align: left;
  padding: 0 4px;
  margin: 0;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  background-color: #fcfcfc;
  border: solid 1px #F1F2F4;
  border-radius: 0 0 8px 8px;
  z-index: 5;
}

.dropdownItem {
  font-size: 14px;
  padding: 12px 16px;
  cursor: pointer;
  user-select: none;
  transition: 0.2s background-color linear;
  margin-top: 4px;
  border-radius: 8px;
}

.dropdownItem:not(.itemSelected):hover {
  background-color: rgba(255, 232, 212, 0.4);
}

.control {
  width: 100%;
  height: 56px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #303337;
  font-weight: 300;
  padding: 0 10px;
  padding-left: 12px;
  transition: color 0.15s linear;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
  &.withCrossIcon {
    &.notFlex {
      .controlValue {
        width: calc(100% - 72px);
        @include adaptive(mob) {
          width: 72%;
        }
      }
    }
  }
  &.notFlex {
    justify-content: flex-end;
    .controlValue {
      position: absolute;
      overflow: hidden;
      width: calc(100% - 45px);
      left: 12px;
      text-overflow: ellipsis;
      white-space: nowrap;
      @include adaptive(mob) {
        width: 80%;
      }
    }
  }
}

.itemSelected {
  cursor: default;
  background-color: #f1f2f4;
}

.toggleIcon {
  width: 20px;
  height: 20px;
  opacity: 0.7;
  margin-left: 3px;
  background: url('img/togle-icon.svg') 50% 50%/ 12px auto no-repeat;
  transition: transform 0.2s linear;
  transform: translateY(1px);
}

.crossIcon {
  width: 15px;
  height: 15px;
  position: relative;
  overflow: hidden;
  pointer-events: none;
  transition: opacity 0.15s linear;
  opacity: 0;
  margin-left: auto;
  &:hover {
    background-color: #dedede;
  }
  &:before {
    content: '';
    display: block;
    height: 2px;
    width: 100%;
    position: absolute;
    transform: rotate(45deg);
    background-color: orange;
    top: 7px;
  }
  &:after {
    content: '';
    display: block;
    height: 2px;
    width: 100%;
    position: absolute;
    transform: rotate(135deg);
    background-color: orange;
    top: 7px;
  }
  &.show {
    pointer-events: auto;
    opacity: 1;
    transition: opacity 0.15s linear;
  }
}

.controlValue {
  margin-right: 20px;
  @include adaptive(mob) {
    max-width: calc(100% - 60px);
    overflow: hidden;
  }
}

.expanded .toggleIcon {
  transform: rotate(180deg);
}

.notFirstElActive {
  color: orange;
}

@media (min-width: 375px) {
  .control {
    font-size: 16px;
  }
}