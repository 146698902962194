@import 'src/css/mixins/mixins';
@value activeTab from 'components/UI/Tabs/Tabs.module.css';
@value orange from 'css/Colors.module.css';

.container {
  padding: 48px 30px;
  padding-top: 40px;
  @include adaptive(mob) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.tabs {
  display: flex;
  grid-gap: 20px;
}

.content {
  margin-top: 30px;
}

.insideTab {
  padding-bottom: 7px;
  &.activeTab {
    color: orange;
  }
}