@value orange from '/src/css/Colors.module.css';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.text {
  font-size: 13px;
  color: #8a96a8;
}

.activeFiltersCount {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  border: 1px orange solid;
  color: orange;
  font-size: 12px;
  position: absolute;
  top: -10px;
  right: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
}