@value gray2 from 'css/Colors.module.css';
@value localBlack from 'css/Colors.module.css';
@import "../../../css/mixins/mixins";
@value filter__container from 'components/UI/Filter/Filter.module.scss';

.page {
  background-color: gray2;
}

.mainContainer {
  padding-top: 40px;
  padding-bottom: 40px;
}

.tabsContainer {
  background-color: #ffffff;
  border-radius: 12px;
}

.dateFilter {
  padding: 32px;
  padding-bottom: 0;
  /*padding-bottom: 20px;
  border-bottom: 2px #E2E5E9 solid;*/
}

.tabsHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  grid-gap: 32px;
  padding-right: 32px;
  position: relative;
  margin-top: -10px;
  &:after {
    content: '';
    height: 2px;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: #E2E5E9;
  }
}

.tabsList {
  &:after {
    display: none;
  }
}

.sort {
  display: flex;
  grid-gap: 15px;
  margin-bottom: 15px;
  @include adaptive(mob) {
    display: none;
  }
  &.disabled {
    color: #acb6c3;
  }
}


.filtersRow {
  padding: 32px;
  padding-bottom: 0;
  display: flex;
  grid-gap: 30px;
  align-items: center;
  @include adaptive(mob) {
    justify-content: space-between;
    grid-gap: 20px;
  }
}

.filters {
  display: flex;
  grid-gap: 10px;
  flex: 1;
  @include adaptive(mob) {
    display: none;
  }
}

.filterItem {
  max-width: 210px;
}

.viewSwitcher {
  margin-top: 5px;
  @include adaptive(mob) {
    margin: 0;
  }
}

.tabPanelContainer {
  padding: 32px;
  @include adaptive(mob) {
    padding: 20px;
  }
}

.searchTitle {
  font-size: 24px;
  margin-bottom: 20px;
  color: localBlack;
  min-height: 35px;
}

.filterMob {
  @include adaptive(not-mob) {
    display: none !important;
  }
}

.filtersCompMob {
  @include adaptive(not-mob) {
    display: none;
  }
  .filter__container {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
  }
}

.sortTitleMob {
  font-size: 24px;
  margin-bottom: 5px;
  margin-top: 15px;
}