.quizQuestion {
  &__title {
    font-size: 16px;
    font-weight: 900;
    color: #484848;
    margin-bottom: 20px;
    &.error {
      color: #a94442;
    }
  }
}