@value orange from 'css/Colors.module.css';

.container {
  display: flex;
  grid-gap: 23px;
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 10px;
  cursor: pointer;
  &:hover {
    .item {
      &__title {
        color: orange;
      }
      &__img {
        fill: orange;
      }
    }
  }
  &.active {
    cursor: default;
    .item {
      &__title {
        color: orange;
      }
      &__img {
        fill: orange;
      }
    }
  }
  &.disabled {
    cursor: default;
    pointer-events: none;
    opacity: .7;
  }
  &__title {
    color: #8A96A8;
    line-height: 120%;
    font-size: 13px;
  }
  &__imgWrapper {
    height: 20px;
  }
  &__img {
    fill: #8A96A8;
    &.table {
      width: 20px;
    }
    &.map {
      width: 23px;
    }
  }
}