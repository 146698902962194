@value gray from 'css/Colors.module.css';
@import "../../../../../css/mixins/mixins";

.slider {
  width: 100%;
}
.slide {
  &__imgWrapper {
    height: 290px;
    border-radius: 8px;
    background-color: gray;
    position: relative;
    overflow: hidden;
    @include adaptive(mob) {
      height: 80vw;
    }
    @include adaptive(mob-landscape) {
      height: 220px;
    }
  }
  &__img {
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 0;
    display: block;
    min-width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center top;
    transform: translateX(-50%);
  }
  &__noImg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  &__inner {
    margin-top: 10px;
  }
  &__title {
    font-size: 18px;
    font-weight: 700;
  }
  &__desc {
    margin-top: 10px;
  }
}