@import "../../../../css/mixins/mixins";

.container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 550px;
  border-radius: 12px;
  overflow: hidden;
  border: 1px #dedede solid;
  background-color: #f6f6f6;
  &.activeArena {
    @include adaptive(mob) {
      border: none;
      border-radius: 0;
      overflow: visible;
    }
  }
}

.mapContainer {
  width: 100%;
  height: 100%;
}

.spinner {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    z-index: -1;
    opacity: .6;
  }
}