.font14{
    font-size: 14px;
}

.wrap {
    width: 100%;
    max-width: 1090px;
    margin: 0 auto;
    display: flow-root;
    padding: 0 15px;
}

.stretchedImg {
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 0;
    display: block;
    min-width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center top;
    object-position: center top;
    transform: translateX(-50%);
}

.spinnerWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
}

.pageTitle {
    font-size: 28px;
    line-height: 150%;
    font-weight: 400;
    margin: 0 0 20px;;
}