@import 'src/css/mixins/mixins';

.section {
    display: flex;
    flex-direction: column;
}

.column1 {
    margin-top: 16px;
    @include adaptive(mob) {
        margin-top: 40px;
    }
}

.column2 {
    margin-top: 16px;
}

@media (max-width: 375px) {
    .column2 {
        margin-top: 28px;
    }
}

@media (min-width: 840px) {
    .section {
        flex-direction: row;
    }

    .column1,
    .column2 {
        margin: 0;
        margin-top: 32px;
    }

    .column1 {
        flex-shrink: 0;
        width: 300px;
    }

    .column2 {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
}