.create-event__playground {
  font-size: 16px;
  margin-bottom: 20px;
}

.create-event__playground-name {
  font-weight: 500;
  margin-right: 10px;
}

.create-event__prices {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -25px;
}

.create-event__price {
  text-align: center;
  margin: 0 25px 25px;
}

.create-event__price-start {
  font-size: 16px;
  line-height: 23px;
  margin-top: 12px;
  cursor: pointer;
  font-weight: 400;
}

.create-event__time {
  height: 34px;
  line-height: 32px;
  border-radius: 30px;
  border: solid 1px #ff6300;
  font-size: 14px;
  text-transform: uppercase;
  color: #ff2500;
  display: inline-block;
  font-weight: 600;
  padding: 0 16px;
  cursor: pointer;
  transition: all 0.2s;
}

.create-event__options {
  max-width: 550px;
  padding-bottom: 15px;
}