@value darkGray from 'css/Colors.module.css';

.select {
    width: 100%;
    display: inline-flex;
    position: relative;
    margin-bottom: 16px;
    min-width: 280px;
}

.dropdown {
    position: absolute;
    min-width: 210px;
    max-height: 250px;
    overflow-y: auto;
    right: 0;
    left: 0;
    top: calc(100% - 2px);
    text-align: left;
    padding: 10px 0;
    margin: 0;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    background-color: #fcfcfc;
    border: solid 1px #F1F2F4;
    z-index: 2;
}

.dropdownItem {
    font-size: 16px;
    padding: 8px 17px 11px 35px;
    cursor: pointer;
    user-select: none;
    transition: 0.2s background-color linear;
}

.dropdownItem:not(.itemSelected):hover {
    background-color: #F1F2F4;
}

.control {
    width: 100%;
    height: 52px;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    color: darkGray;
    font-weight: 400;
    transition: color 0.2s linear;
    cursor: pointer;
    user-select: none;
    white-space: nowrap;
    background: #F1F2F4;
    border-radius: 8px;
    position: relative;
}

.controlValue{
    width: 100%;
    font-weight: 600;
    color: #2f3441;
    background: #f1f2f4;
    border-radius: 8px;
    padding: 24px 16px 10px;
    border: 0;
}

.label{
    composes: formInputLabel from '../FormInput/FormInput.module.css';
}

.labelActive{
    composes: formInputLabel_active from '../FormInput/FormInput.module.css';
}

.itemSelected {
    cursor: default;
    background: #F1F2F4 10px 50% no-repeat;
}

.expanded .toggleIcon {
    transform: rotate(180deg);
}

.toggleIcon {
    opacity: 0.7;
    margin-left: 3px;
    transition: transform 0.2s linear;
    width: 52px;
    height: 52px;
    background: url('img/toggleIcon.svg') 50% 50% no-repeat;
    position: absolute;
    top:0;
    bottom: 0;
    right: 0;
}
