@value orange from 'css/Colors.module.css';

.navLink {
  display: flex;
  flex-direction: column;
  user-select: none;
  cursor: pointer;
  padding-bottom: 7px;
  position: relative;
  &:hover {
    &:after {
      opacity: 1;
      transition: opacity .15s ease;
    }
    .navLink {
      &__text {
        color: orange;
        transition: color .15s ease;
      }
      &__topPrefix {
        color: orange;
        transition: color .15s ease;
      }
    }
  }
  &.active {
    color: orange;
    &:after {
      opacity: 1;
      transition: opacity .15s ease;
    }
    .navLink {
      &__topPrefix {
        color: orange;
        transition: color .15s ease;
      }
    }
  }
  &__text {
    font-size: 16px;
    font-weight: 400;
    line-height: 130%;
    transition: color .15s ease;
    margin: 0;
  }
  &__topPrefix {
    font-size: 14px;
    font-weight: 400;
    line-height: 130%;
    color: #949494;
    margin-bottom: 10px;
    transition: color .15s ease;
  }
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background-color: orange;
    position: absolute;
    left: 0;
    bottom: 0;
    opacity: 0;
    transition: opacity .15s ease;
  }
}