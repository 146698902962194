@value orange from 'css/Colors.module.css';

.orangeLink {
    cursor: pointer;
    color: orange;
    border-bottom: 1px solid orange;
    width: fit-content;
}

.orangeLink:hover {
    color: #ff0000;
    border-color: #ff0000;
}