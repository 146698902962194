.react-select__control{
    height: 58px;
    border: 0;
    border-bottom: 2px solid rgba(151,151,151,.6);
    -webkit-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-size: 16px;
    font-weight: 500;
    color: #000;
    font-family: GolosText,Roboto,sans-serif;
    padding: 0 8px;
    text-align: left;
    position: relative;
    outline: 0;
}

.react-select__control--menu-is-open{
    border-color: #fa4824;
}

.react-select__indicators{
    position: absolute;
    right: 0;
    top: 2px;
    height: 100%;
    width: 30px;
    /*background: url(../images/arrow-large.svg) no-repeat center/12px 8px;*/
}

.react-select__indicator{
    line-height: 56px;
}

.react-select__value-container {
    padding-left: 0;
    height: 56px;
}

.react-select__single-value{
    height: 56px;
    line-height: 56px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-right: 20px;
}

.react-select__menu{
    -webkit-box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);
    background-color: #fcfcfc;
    border: solid 1px #ffdbbb;
    -webkit-border-radius: 0;
    border-radius: 0;
}

.react-select__menu-list{
    max-height: 250px;
    overflow-y: auto;
    list-style: none;
    margin: 0;
    padding: 5px 0;

}

.react-select__option{
    font-size: 16px;
    padding: 8px 17px 11px;
    cursor: pointer;
}

.react-select__option--is-selected{
    background-color: #ffe8d4;
}

.react-select__placeholder{
    line-height: 56px;
}