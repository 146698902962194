.item {
  background-color: #ffffff;
  &:hover {
    filter: none
  }
}

.playersCount {
  font-weight: 700;
  line-height: 36px;
  font-size: 12px;
  color: #101010;
  margin-left: 5px;
}