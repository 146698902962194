.formInput {
  position: relative;
  width: 100%;
  margin-bottom: 14px;
  border: 1px solid transparent;
}

.formInput.green{
  border: 1px solid #219246;
  border-radius: 8px;
}

.formInput.inputError{
  border: 1px solid red;
  border-radius: 8px;
}

.formInputField {
  width: 100%;
  font-weight: 600;
  color: #2f3441;
  background: #f1f2f4;
  border-radius: 8px;
  padding: 24px 16px 10px;
  border: 0;
}

.formInputField.inputTextarea {
  height: 150px;
  resize: none;
}

.formInputLabel {
  position: absolute;
  pointer-events: none;
  left: 16px;
  top: 18px;
  transition: 0.2s ease all;
  font-size: 14px;
  line-height: 13px;
  color: #6b7683;
}

.formInputLabel_active {
  top: 8px;
  bottom: 10px;
  left: 16px;
  font-size: 12px;
  letter-spacing: 0.3px;
}

.formInputField::-webkit-input-placeholder {
  opacity: 0;
}

.formInputField::-moz-placeholder {
  opacity: 0;
}

.formInputField:-ms-input-placeholder {
  opacity: 0;
}

.formInputClear {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0.6;
  width: 52px;
  background: transparent url(img/close-icon.svg) 50% 50% no-repeat;
  transition: opacity 0.2s linear;
  border: none;
  cursor: pointer;
}

.formInputClear:hover {
  opacity: 0.85;
}

.formInputClear:active {
  opacity: 1;
}

.togglePassVisible {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0.6;
  width: 52px;
  transition: opacity 0.2s linear;
  border: none;
  cursor: pointer;
  background: transparent 50% 50% no-repeat;
}

.togglePassVisible:hover {
  opacity: 0.85;
}

.togglePassVisible:active {
  opacity: 1;
}

.togglePassVisibleShow {
  composes: togglePassVisible;
  background-image: url(img/show-pass-icon.svg);
}

.togglePassVisibleHide {
  composes: togglePassVisible;
  background-image: url(img/hide-pass-icon.svg);
}
