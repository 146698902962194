@mixin adaptive($size) {
  @if $size == mob {
    @media (max-width: 900px) { @content; }
  }
  @if $size == mob2 {
    @media (max-width: 600px) { @content; }
  }
  @if $size == mw1020 {
    @media (max-width: 1020px) { @content; }
  }
  @if $size == mob-landscape {
    @media (max-height: 900px) and (max-width: 1023px) and (orientation: landscape) { @content; }
  }
  @if $size == not-mob {
    @media (min-width: 901px) { @content; }
  }
}

@mixin line-clamp($numberOfLines) { // <number> | unset
  word-break: break-word;

  @if $numberOfLines == unset {
    -webkit-line-clamp: unset;
  } @else {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $numberOfLines;
    overflow: hidden
  }
}