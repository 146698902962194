.modalIsOpen {
  display: flex;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.modalIsOpen:after {
  display: block;
  content: '';
  overflow-y: scroll;
}

.overlay {
  position: fixed;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(47, 52, 65, 0.5);
  overflow-y: scroll;
  z-index: 1000;
  padding: 20px;
}

.modal {
  position: relative;
  width: 100%;
  max-width: 406px;
  padding: 45px 32px 32px;
  margin: auto 0;
  background: #ffffff;
  border-radius: 12px;
}

.modal__close {
  display: block;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 8px;
  right: 8px;
  opacity: 0.6;
  background: transparent url(./close-icon.svg) 50% 50% no-repeat;
  transition: opacity 0.2s linear;
  border: none;
  cursor: pointer;
}

.modal__close:hover {
  opacity: 0.85;
}

.modal__close:active {
  opacity: 1;
}

@media (max-width: 480px) {
  .overlay {
    padding: 0;
  }

  .modalIsOpen {
    display: block;
  }

  .modal {
    flex: 1;
    max-width: 100%;
    border-radius: 0;
    margin: 0;
    min-height: 100vh;
  }

  .modal:after {
    content: '';
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 200vw;
    background-color: white;
    z-index: -1;
  }
}
