@import "../../../../css/mixins/mixins";
@value lightGray from 'css/Colors.module.css';

.loadMore {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 20px;
  margin: 40px 0;
  min-height: 10px;
  @include adaptive(mob) {
    margin: 20px 0;
  }
  &__spinner {
    cursor: default;
  }
  &__text {
    font-weight: 500;
    cursor: pointer;
    padding: 7px 10px;
    background: #f7f7f7;
    border: 1px lightGray solid;
    border-radius: 5px;
  }
}

.grid {
  --cols: 2;
  @include adaptive(mob) {
    --cols: 2;
  }
  @include adaptive(mob2) {
    --cols: 1;
  }
}