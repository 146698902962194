.active{}

.tabList {
    display: flex;
    padding: 32px 32px 0;
    gap: 32px;
    position: relative;
}

.wrapper {
    position: relative;
}

.wrapper:after {
    content: '';
    height: 2px;
    width: 100%;
    background-color: #E2E5E9;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 0;
}

.tab {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    color: #626F84;
    padding-bottom: 12px;
    cursor: pointer;
    flex-shrink: 0;
    position: relative;
    z-index: 1;
}

.tab:hover {
    opacity: 0.8;
}

.activeTab {
    border-bottom: 2px solid #FF6620;
    color: #2B313B;
    pointer-events: none;
    cursor: default;
}

@media (max-width: 900px) {
    .tabList{
        overflow-x: scroll;
        overflow-y: clip;
        -webkit-overflow-scrolling: touch; /* Momentum Scrolling on iOS Overflow Elements */
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }

    /* Hide scrollbar for Chrome, Safari and Opera */
    .tabList::-webkit-scrollbar {
        display: none;
    }
}

.tabPanel {
    width: 100%;
    height: 100%;
}