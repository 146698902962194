@import 'src/css/mixins/mixins';

.container {
  padding-top: 60px;
  padding-bottom: 60px;
}

.formWrapper {
  max-width: 330px;
  width: 100%;
  @include adaptive(mob) {
    max-width: none;
  }
}