.formInputField {
    height: 58px;
    border: 0;
    border-bottom: 2px solid rgba(151,151,151,.6);
    border-radius: 0;
    box-shadow: none;
    font-size: 16px;
    font-weight: 500;
    color: #000;
    font-family: GolosText,Roboto,sans-serif;
    padding: 0 8px;
    text-align: left;
    position: relative;
    outline: 0;

    width: 100%;
}