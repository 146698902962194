@import "../../../css/mixins/mixins";
@value orange from '/src/css/Colors.module.css';

.filter {
  flex: none;
  border-radius: 8px;
  padding: 24px 24px 32px;
  width: 326px;
  position: sticky;
  top: 24px;
  height: 100%;
  background-color: #f7f7f7;
  margin-bottom: 24px;
  @include adaptive(mw1020) {
    display: none;
  }
  &.openedOnMob {
    display: block;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 0;
    z-index: 99999;
  }
  &__titleRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 20px;
    margin-top: 25px;
    margin-bottom: 15px;
  }
  &__title {
    font-size: 24px;
    font-weight: 500
  }
  &__clear {
    color: orange;
  }
  &__close {
    display: none;
    @include adaptive(mw1020) {
      display: flex;
      justify-content: flex-end;
    }
  }
  &__container {
    color: initial;
  }
}