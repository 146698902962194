.container {
  width: 100%;
  background: #F7F7F7;
  border-radius: 8px;
  padding: 36px 32px 37px;
}

.title {
  font-size: 20px;
  color: #2B313B;
}

.itemList {
  margin-top: 24px;
}

.item {
  box-sizing: border-box;
  padding: 5px 18px;
  margin-top: 10px;
  font-size: 16px;
  border-left: 2px solid transparent;

  &:not(.selectedItem) {
    cursor: pointer;
  }

  &.selectedItem {
    border-left: 2px solid #FF6620;
    font-weight: 500;
  }
}