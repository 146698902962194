.createGame {
  max-width: 570px;
  padding-bottom: 35px;
  &__info {
    font-size: 20px;
    line-height: 1.7;
    color: #417505;
    margin-bottom: 40px;
  }
  &__form {
    max-width: 478px;
  }
  &__form__price {
    display: inline-block;
    vertical-align: middle;
    font-size: 18px;
    line-height: 52px;
  }
}