@import 'src/css/mixins/grid';
@import 'src/css/mixins/mixins';

.container {
    padding-bottom: 60px;
}

.pageAlert.success {
    background-color: #3cb870;
}
.pageAlert.error {
    background-color: #f15b5c;
}
.pageAlert {
    background-color: #d0d0d0;
    color: #fff;
    font-size: 20px;
    text-align: center;
    padding: 14px;
    margin-bottom: 15px;
}

.gameInfoRow {
    display: flex;
    grid-gap: 30px;
    @include adaptive(mob) {
        grid-gap: 20px;
        flex-direction: column;
    }
}

.gameInfoContainer {
    background-color: #fff;
    border: 1px solid #ededed;
    padding: 22px 30px;
    border-radius: 18px;
    flex: 1;
    @include adaptive(mob) {
        padding: 15px 20px;
    }
}

.gameInfoPlaceHead {
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    grid-gap: 20px;
    @include adaptive(mob) {
        margin-bottom: 15px;
        grid-gap: 10px;
    }
}

.gameInfoPlaceTitle {
    font-size: 24px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #000;
    display: inline-block;
    line-height: 40px;
    @include adaptive(mob) {
        font-size: 20px;
        line-height: 130%;
    }
}

.share {
    position: relative;
    flex-shrink: 0;
    &.open {
        .shareButton {
            border-color: #fe6320;
            background-color: #fe6320;
            color: #fff;
            text-decoration: none;
            &:after {
                -webkit-filter: invert(1);
            }
        }
    }
}

.shareButton {
    height: 39px;
    border: solid 1px #d7d7d7;
    display: inline-block;
    background-color: #fff;
    color: #fe6320;
    text-transform: uppercase;
    font-size: 12px;
    text-align: center;
    padding: 0 20px;
    line-height: 38px;
    -webkit-transition: all .2s;
    transition: all .2s;
    border-radius: 8px;
    cursor: pointer;
    &:after {
        content: '';
        display: inline-block;
        background: url('img/share.svg') no-repeat center;
        margin-left: 12px;
        vertical-align: middle;
        position: relative;
        top: -2px;
        width: 12px;
        height: 14px;
    }
}

.shareDropdown {
    width: 330px;
    -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .05);
    box-shadow: 2px 2px 10px 3px rgba(0, 0, 0, 0.15);
    background-color: #fff;
    border: solid 1px #dfdfdf;
    text-align: center;
    left: 50%;
    margin-left: -165px;
    margin-top: 9px;

    position: absolute;
    top: 100%;
    display: none;
    padding: 10px 10px 20px;
    box-sizing: content-box;
    @include adaptive(mob) {
        top: auto;
        left: auto;
        right: -22px;
        margin-left: 0;
        max-width: calc(100vw - 50px);
    }
    &.openDropdown {
        display: block;
    }
    &:before {
        content: '';
        width: 12px;
        height: 12px;
        background-color: #fff;
        border: 1px solid #dfdfdf;
        border-right: 0;
        border-bottom: 0;
        -webkit-transform: rotate(
                        45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(
                        45deg);
        position: absolute;
        left: 50%;
        top: -6px;
        margin-left: -6px;
        @include adaptive(mob) {
            left: auto;
            right: 40px;
        }
    }
    p {
        font-size: 16px;
        margin-bottom: 16px;
    }
    &__links {
        text-align: center;
        font-size: 0;
        margin-bottom: 30px;
        a {
            margin: 0 4px;
            display: inline-block;
        }
    }
    &__link {
        color: #9a9a9a;
        border-bottom: 1px solid #d3d3d3;
        padding-bottom: 15px;
        margin-bottom: 20px;
        word-break: break-all;
    }
    &__copy {
        font-size: 14px;
        line-height: 1.64;
        letter-spacing: 1.1px;
        color: #fe6320;
        text-transform: uppercase;
        cursor: pointer;
    }
}

.cancelGame {
    font-size: 13px;
    color: #9b9b9b;
    position: relative;
    top: 7px;
    text-decoration: none !important;
    cursor: pointer;
    &:hover {
        text-decoration: underline !important;
    }
}

.emoji {
    display: inline-block;
    font-size: 18px;
    margin-left: 8px;
    vertical-align: middle;
}

.infoCountPlayers {
    text-decoration: underline;
    cursor: pointer;
    font-size: 14px;
    line-height: 23px;
    font-weight: 700;
    color: #fe6320;
}

.tooltip {
    max-width: 200px;
    padding: 3px 8px;
    text-align: center;
    line-height: 1.42857143;
    font-weight: 400;
    font-size: 12px;
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
}



.coachBlock {
    margin-left: 10px;
}
.coachItem {
    display: flex;
    align-items: center;
}

.pageTitle {
    font-size: 28px;
    line-height: 150%;
    font-weight: normal;
    margin: 0;
    margin-bottom: 20px;
    @include adaptive(mob) {
        font-size: 22px;
        line-height: 130%;
        margin-bottom: 15px;
    }
}

.join {
    border: 1px dashed #62ce67;
    border-radius: 8px;
    padding: 15px;
    min-height: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap: 10px;
    &.paymentOnPlace {
        border-color: #ff6620;
    }
    .joinPartnerWrapper {
        display: flex;
        grid-gap: 10px;
        flex-direction: column;
        justify-content: center;
        .price {
            text-align: center;
        }
    }
}

.btn {
    font-weight: 700;
    background-color: transparent;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    border: solid 1px #4cce61;
    color: #08961f;
    padding: 11px 20px;
    -webkit-transition: all .2s;
    transition: all .2s;
    cursor: pointer;
    &:hover {
        text-decoration: none;
        background-color: #4cce61;
        color: #fff;
    }
    &:before {
        content: '+';
        margin-right: 10px;
    }
    &.orange {
        color: #FF6620;
        border-color: #ff8449;
        &:hover {
            background-color: #ff8449;
            color: #fff;
        }
    }
}

.price {
    font-size: 13px;
    color: #979797;
    &.black {
        color: #101010;
    }
}

.list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    grid-gap: 15px;
    @include adaptive(mob) {
        grid-gap: 10px;
    }
    li {
        font-size: 14px;
        line-height: 23px;
        font-weight: 700;
        span {
            font-weight: 400;
            margin-right: 3px;
        }
        &.dotted {
            height: 1px;
            border-top: 1px dotted #d4d4d4;
            margin-top: 1px;
        }
    }
}

.membersSect {
    margin-top: 40px;
    &__title {
        font-size: 24px;
        margin-bottom: 20px;
        @include adaptive(mob) {
            margin-bottom: 15px;
        }
    }
}

.members {
    --gap: 20px;
    --cols: 3;
    @include grid;
    @include adaptive(mob) {
        --cols: 2;
        --gap: 15px
    }
    @include adaptive(mob2) {
        --cols: 1;
    }
}

.commentSect {
    margin-top: 30px;
    @include adaptive(mob) {
        margin-top: 20px;
    }
    p {
        margin: 0;
        line-height: 150%;
    }
}

.recommendationsSect {
    margin-top: 60px;
    &__title {
        font-size: 24px;
        margin-bottom: 20px;
    }
}