@import "../../../../css/mixins/mixins";
@value navLink__text from '/src/components/UI/NavLink/NavLink.module.scss';

.container {
  display: flex;
  grid-gap: 28px;
  padding-bottom: 10px;
  @media (max-width: 1199px) {
    overflow-y: hidden;
    overflow-x: scroll;
    &::-webkit-scrollbar {
      width: 2px;
      height: 2px;
    }
    &::-webkit-scrollbar-track {
      background-color: #f0f0f0;
    }
    &::-webkit-scrollbar-thumb {
      height: 30%;
      background-color: #d0d0d0;
    }
  }
}

.item {
  font-size: 16px;
  &:first-child {
    margin-right: 28px;
    position: relative;
    @include adaptive(mob) {
      margin-right: 20px;
    }
    &:before {
      content: '';
      display: block;
      position: absolute;
      right: -30px;
      top: 3px;
      width: 1px;
      height: 100%;
      background-color: #d6d6d7;
      @include adaptive(mob) {
        right: -24px;
      }
    }
    .navLink__text {
      font-weight: 700;
    }
  }
  .navLink__text {
    &::first-letter {
      text-transform: uppercase;
    }
  }
}