.error{
    color: red;
    margin: 5px 0;
}
.buttonDisabled button {
    cursor: default !important;
    pointer-events: none !important;
    background-color: #acb6c3;
}

.required label:after {
    content: '*';
    margin-left: 5px;
    color: red;
}