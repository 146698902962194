.header{
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
}

.text{
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
}

.emailSend{
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
    color: #8A96A8;
    text-decoration-line: underline;
}

.emailSendOrange {
    @extend .emailSend;
    color: #FF6620;
    text-decoration-line: none;
    cursor: pointer;
}
