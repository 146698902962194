.balloon {
    width: 270px;
    display: flex;
    align-items: flex-start;
    grid-gap: 15px;
}

.balloon__imgWrapper {
    border: 1px #BCBCBC solid;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
    width: 40px;
    height: 40px;
    flex-shrink: 0;
}

.balloon__img {
    position: absolute;
    width: 100%;
}

.balloon__textSide {
    display: flex;
    flex-direction: column;
    grid-gap: 7px;
    font-size: 14px;
    line-height: 120%;
}

.balloon__title {
    font-weight: bold;
}

.balloon__bottom {
    display: flex;
    justify-content: space-between;
    grid-gap: 10px;
    flex-wrap: wrap;
}

.balloon__more,
.balloon__createLink {
    color: #FF6620;
    font-weight: bold;
    cursor: pointer;
}

.balloon__more:hover,
.balloon__createLink:hover {
    text-decoration: underline;
}

.balloon__createLink.disabled {
    pointer-events: none;
    color: #acb6c3;
    font-size: 12px;
}