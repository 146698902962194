@value orange from 'css/Colors.module.css';

.card {
  border: 1px solid #fa4824;
}

.topRow {
  padding: 7px 15px;
  background-color: #f1f2f4;
  border-bottom: 1px #e5e5e5 solid;
  display: flex;
  justify-content: space-between;
  grid-gap: 20px;
  color: orange;
}

.header {
  display: flex;
  align-items: flex-start;
  padding: 5px 0;
  border-bottom: 1px solid #fa4824;
  justify-content: space-between;
  grid-gap: 15px;
}

.headerInfo {
  flex: none;
  display: flex;
  flex-direction: column;
  grid-gap: 7px;
}

.label {
  color: #fa4824;
  font-size: 12px;
  font-weight: bolder;
  margin-bottom: 6px;
}

.dates {
  font-weight: 700;
}

.text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: normal;
  &.orange {
    color: #fa4824;
  }
}

.logo {
  flex: none;
  height: 60px;
  width: 60px;
  background: #fff;
  border-radius: 50%;
  border: 1px solid #ebebeb;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  img {
    background-color: #d0d0d0;
    display: block;
    height: 100%;
    left: 50%;
    min-width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center top;
    object-position: center top;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    z-index: 0;
  }
  &:hover {
    filter: brightness(.85);
  }
}

.contentContainer {
  padding: 5px 15px;
  flex: 1;
}

.content {
  display: flex;
  justify-content: space-between;
  padding: 14px 20px 0;
  flex: 1;
}

.info {
  display: flex;
  flex-direction: column;
  grid-gap: 7px;
}

.infoLine {
  margin-bottom: 6px;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  position: relative;
  color: #2b313b;
  background-color: #f1f2f4;
  border-radius: 0 0 12px 12px;
  width: 100%;
  height: 56px;
  text-decoration: none;

  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    right: 20px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    height: 24px;
    width: 24px;
    background: url('../../../Home/img/card-game-arrow.svg') 50% 50% no-repeat;
    transition: 0.3s all;
  }

  &:hover {
    &::after {
      right: 12px;
    }
  }
}

.footerLink {
  display: flex;
  align-items: center;
  justify-content: end;
  cursor: pointer;
  color: black;
  text-decoration: none;
  padding: 5px 15px;
  margin-top: -10px;

  &::after {
    content: '';
    height: 24px;
    width: 24px;
    margin-left: 8px;
    background: url('../../../Home/img/card-game-arrow.svg') 50% 50% no-repeat;
    transition: 0.3s transform;
  }

  &:hover {
    &::after {
      transform: translateX(10px);
    }
  }
}

.contentItem {
  padding: 7px 0;
}