.checkboxGroup {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  margin: 0 -5px;
  &__title {
    font-size: 16px;
    font-weight: 900;
    color: #484848;
  }

  &.inbox {
    display: flex;
    flex-wrap: wrap;
  }
}