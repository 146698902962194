.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
}

.title {
  font-size: 32px;
  text-align: center;
  margin-bottom: 20px;
}