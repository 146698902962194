.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
}

.button {
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}