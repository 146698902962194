.formGroup{

}

.formControl{
    display: block;
    width: 100%;
    height: 56px;
    border: 0;
    border-bottom: 2px solid rgba(151,151,151,.6);
    padding: 0 13px;
    box-shadow: none;
    border-radius: 0;
    font-weight: 400;
    text-align: center;
    border-bottom: 2px solid rgba(151,151,151,.35);
}

.formControl.error {
    border-color: red;
    background: url(./img/error.png) no-repeat 10px 50%;
    padding-left: 30px;
}