.layoutWrap{
    background-color: #f1f2f4;
    padding-top: 45px;
    flex: 1;
}

.title{
    font-size: 24px;
    line-height: 26px;
    padding-bottom: 32px;
    font-weight: 500;
}

.content{
    background-color: #ffffff;
    border-radius: 12px;
    margin-bottom: 64px;
}

.exit{
    color: #FF6620;
    margin-left: auto;
    margin-right: 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    cursor: pointer;
}

.exit.mobile{
    display: none;
}

.exit:hover{
    opacity: 0.8;
}

@media (max-width: 1120px) {
    .title{
        padding-left: 20px;
    }
}

@media (max-width: 900px) {
    .exit{
        display: none;
    }
    .exit.mobile{
        display: block;
        margin-left: initial;
    }
    .content{
        margin-bottom: 0;
        border-radius: 0;
        min-height: 450px;
        background-color: #FFFFFF;
    }
}