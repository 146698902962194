@import "../../../../../css/mixins/mixins";

.quiz {
  padding: 32px;
  width: 50%;
  @include adaptive(mob) {
    width: 100%;
    padding: 30px 20px;
  }
  &__questions {
    margin-top: 30px;
    div:not(:first-child) {
      margin-top: 30px;
    }
  }
  &__button {
    margin-top: 60px;
  }
  &__errorBlock {
    color: #a94442;
    background-color: #f2dede;
    margin-top: 20px;
    border: 1px solid #ebccd1;
    border-radius: 4px;
    padding: 15px;
  }
}

