@value lightGray from 'css/Colors.module.css';

.card {
  width: 100%;
  flex: none;
  font-size: 14px;
  line-height: 13px;
  background-color: #ffffff;
  box-shadow: 0 2px 10px 0 rgb(160 170 194 / 12%);
  border: solid 1px lightGray;
  border-radius: 16px;
  position: relative;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  &.highlighted {
    box-shadow: 0 2px 10px 0 rgb(250 72 36 / 20%);
    background-color: #ffffff;
    border-color: #fa4824;
  }
  &.orange {
    background-color: #ffffff;
    box-shadow: 0 2px 10px 0 rgb(160 170 194 / 12%);
    border: solid 1px #fa4824;
  }
  &.blue {
    border-color: #1f7392;
  }
  &.pink {
    border-color: #ff728a;
  }
}