@import 'src/css/mixins/mixins';
@import 'src/css/mixins/grid';

.grid {
  flex-wrap: wrap;

  --gap: 20px;
  --cols: 3;

  @include grid;

  @include adaptive(mob) {
    --cols: 2;
  }

  @include adaptive(mob2) {
    --cols: 1;
  }
}