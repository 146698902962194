.widget {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  height: 20px;
  width: 20px;
  padding: 2px;
  cursor: pointer;
}

.icon {
  flex-shrink: 0;
  max-width: 20px;
  max-height: 20px;
  overflow: hidden;
}

.label {
  position: absolute;
  right: -2px;
  top: -2px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 2px;
  height: 12px;
  min-width: 12px;
  background: #FF6620;
  border-radius: 12px;
  font-size: 8px;
  color: white;
  font-weight: 300;
}

