.select {
  width: 100%;
  display: inline-flex;
  position: relative;
}
.select.disabled {
  cursor: default;
  pointer-events: none;
  opacity: .4;
}

.dropdown {
  position: absolute;
  min-width: 210px;
  max-height: 250px;
  overflow-y: auto;
  right: 0;
  left: 0;
  top: calc(100% - 2px);
  text-align: left;
  padding: 10px 0;
  margin: 0;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: #fcfcfc;
  border: solid 1px #ffdbbb;
  z-index: 2;
}

.dropdownItem {
  font-size: 16px;
  padding: 8px 17px 11px 35px;
  cursor: pointer;
  user-select: none;
  transition: 0.2s background-color linear;
}

.dropdownItem:not(.itemSelected):hover {
  background-color: rgba(255, 232, 212, 0.4);
}

.control {
  width: 100%;
  height: 56px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  color: #000;
  font-weight: 300;
  padding: 0 8px;
  border-bottom: 1px solid #cfcfcf;
  transition: color 0.2s linear;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
}

.itemSelected {
  cursor: default;
  background: #ffe8d4 url('img/check-icon.svg') 10px 50% no-repeat;
}

.toggleIcon {
  width: 20px;
  height: 20px;
  opacity: 0.7;
  margin-left: 3px;
  background: url('img/togle-icon.svg') 50% 50%/ 12px auto no-repeat;
  transition: transform 0.2s linear;
  transform: translateY(1px);
}

.expanded .toggleIcon {
  transform: rotate(180deg);
}