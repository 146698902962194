@value orange from 'css/Colors.module.css';
@value img from 'components/UI/Loader/Loader.module.scss';

.cardContainer {
    display: flex;
    padding: 48px;
    gap: 34px;
}

.cardItem {
    display: flex;
    flex-direction: column;
    gap: 24px;
    &.userRemoved {
        justify-content: center;
    }
}

.name {
    text-transform: capitalize;
}

.contacts {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.cardItem.deposit {
    padding-left: 100px;
}

.social {
    display: flex;
    gap: 15px;
}

.social > img {
    width: 24px;
    height: 24px;
}
.deleteButton {
    margin-top: 15px;
}

@media (max-width: 900px) {
    .cardContainer {
        flex-direction: column;
        align-items: center;
    }

    .cardItem {
        align-items: center;
    }

    .cardItem.deposit {
        padding-left: 0;
    }
    .contacts {
        align-items: center;
    }
}

.removeProfile {
    margin-top: auto;
}

.removed {
    color: orange;
}

.refundsRow {
    display: flex;
    grid-gap: 10px;
}

.refundsLoader {
    flex-shrink: 0;
    margin-top: -4px;
    opacity: 0;
    pointer-events: none;
    .img {
        width: 20px;
        height: 20px;
    }
    &.active {
        opacity: 1;
    }
}

.depositPayment {
    max-width: 300px;
}