@value orange from 'css/Colors.module.css';
@import "css/mixins/dots";

.eventTypeRow {
  padding: 7px 15px;
  background-color: #f1f2f4;
  border-bottom: 1px #e5e5e5 solid;
  display: flex;
  justify-content: space-between;
  grid-gap: 20px;
  &__type {
    color: orange;
  }
  &__arena {
    @include dots;
  }
}

.container {
  padding: 5px 15px;
  flex: 1;
}

.contentItem {
  padding: 7px 0;
  &:not(:last-child) {
    border-bottom: 1px solid #d3d8de;
  }
}

.mainContent {
  display: flex;
  justify-content: space-between;
  grid-gap: 10px;
  &__textSide {
    display: flex;
    flex-direction: column;
    grid-gap: 7px;
    @include dots;
    flex: 1;
  }
  &__arenaLogoSide {
    flex-shrink: 0;
  }
  &__firstRow {
    display: flex;
    grid-gap: 7px;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

.price {
  color: orange;
  font-weight: bold;
  flex-shrink: 0;
}

.sport {
  font-weight: bold;
  @include dots;
}

.level {
  @include dots;
}

.arenaLogo {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 60px;
  background: #fff;
  border-radius: 50%;
  border: 1px solid #ebebeb;
  overflow: hidden;
  position: relative;
  &:hover {
    filter: brightness(.85);
  }
  img {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.formatRow {
  display: flex;
  justify-content: space-between;
  grid-gap: 20px;
}

.coachRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 20px;
}

.coachName {
  @include dots;
}

.coachPhoto {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  background: #fff;
  border-radius: 50%;
  border: 1px solid #ebebeb;
  overflow: hidden;
  position: relative;
  margin-right: 12px;
  flex-shrink: 0;
  img {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.comment {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  white-space: pre-wrap;
}

.bottomRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 15px;
  position: relative;
  color: #2b313b;
  background-color: #f1f2f4;
  width: 100%;
  text-decoration: none;
  border-top: 1px #e5e5e5 solid;
  min-height: 55px;
  flex-wrap: wrap;
  grid-gap: 7px;
  cursor: pointer;
  &:hover {
    background-color: #e8e8ea;
    .freeSlots {
      &__value {
        &:after {
          transform: translateX(10px);
          transition: transform .3s;
        }
      }
    }
  }
}

.members {
  display: flex;
}

.member {
  margin-right: -14px;
  &:nth-child(1) {
    z-index: 4;
  }
  &:nth-child(2) {
    z-index: 3;
  }
  &:nth-child(3) {
    z-index: 2;
  }
  &:nth-child(4) {
    z-index: 1;
  }
}

.freeSlots {
  display: flex;
  align-items: center;
  &__value {
    color: orange;
    font-weight: bold;
    margin-left: 7px;
    display: flex;
    align-items: center;
    &:after {
      content: "";
      display: block;
      height: 24px;
      width: 24px;
      margin-left: 0;
      background-image: url("/components/Pages/Home/img/card-game-arrow.svg");
      background-size: cover;
      background-position: center;
      transition: transform .3s;
    }
  }
}

.man {
  border-color: #1f7392 !important;
  .bottomRow {
    background-color: #b7e4ff;
    border-color: #1f7392;
    &:hover {
      background-color: #a1e0fd;
    }
  }
}

.woman {
  border-color: #ff728a !important;
  .bottomRow {
    background-color: #ffcfd7;
    border-color: #ff728a;
    &:hover {
      background-color: #ffc1c9;
    }
  }
}

.hard {
  box-shadow: 0 2px 20px 0 rgb(250 72 36 / 30%) !important;
  background-color: #ffffff !important;
  border-color: #fa4824 !important;
  .bottomRow {
    border-color: #fa4824;
  }
}

.limits {
  display: flex;
  grid-gap: 10px;
  align-items: flex-end;
  &__icon {
    margin-bottom: 2px;
    max-width: 14px;
  }
}