.container {
  display: flex;
  grid-gap: 25px;
}

.year {
  font-size: 16px;
  font-weight: 400;
  color: #838587;
  cursor: pointer;
  &:hover {
    color: #303337;
  }
  &.active {
    color: #303337;
    cursor: default;
    font-weight: 500;
  }
}