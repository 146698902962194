@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap&subset=cyrillic');
@import-normalize;

@font-face {
  font-family: GolosText;
  src: url(../fonts/golosTextRegular.woff2) format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: GolosText;
  src: url(../fonts/golosTextMedium.woff2) format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: GolosText;
  src: url(../fonts/golosTextBold.woff2) format('woff2');
  font-weight: 700;
  font-style: normal;
}

body {
  margin: 0;
  font: 16px GolosText, Roboto, sans-serif;
  color: #2b313b;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
  /*overflow-x: hidden;*/
}

* {
  box-sizing: border-box;
  outline: transparent;
  -webkit-tap-highlight-color: transparent;
}

img {
  flex: none;
  max-width: 100%;
}

h1 {
  font-size: 40px;
  /*font-size: 24px;*/
  font-weight: 700;
}

.f-bold {
  font-weight: 700;
}

.text-center {
  text-align: center;
}

.color-orange {
  color: #ff6620;
}

.h1,
.h2,
.h3,
h1,
h2,
h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}

h2 {
  font-size: 32px;
  padding: 5px 0;
}

h3 {
  font-size: 24px;
  padding: 5px 0;
}

.wrap {
  width: 100%;
  max-width: 1090px;
  padding: 0 15px;
  margin: 0 auto;
  display: flow-root;
}

input,
button,
textarea {
  font: 16px/18px GolosText, Roboto, sans-serif;
}

#root {
  width: 100%;
  /*height: 100vh;*/
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

@media (max-width: 900px) {
  .wrap {
    padding: 0 20px;
  }
}

@media (max-width: 600px) {
  h2 {
    font-size: 24px;
  }
}

html {
  width: 100vw;
  overflow-x: hidden;
}

.noScroll {
  overflow: hidden !important;
}