.container {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 9999;
  overflow: visible;
}

.button {
  position: relative;
  display: block;
  width: 62px;
  height: 62px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.08);
  border: 4px solid #ff6620;
  border-radius: 31px;
  background-color: white;
  background-image: url('../../../../imgs/question-mark.svg');
  background-size: 12px 22px;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  z-index: 2;
}

.hint {
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  white-space: nowrap;
  padding: 0 25px;
  height: 62px;
  background: white;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.08);
  border-radius: 31px;
  opacity: 0;
  z-index: 1;
  transition: opacity 0.3s, right 0.3s;
  pointer-events: none;
}

@media (min-width: 900px) {
  .container {
    right: 60px;
    bottom: 60px;

    &:hover {
      .hint {
        left: auto;
        right: 62px + 15px;
        opacity: 1;
      }
    }
  }
}