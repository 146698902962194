.title {
  font-size: 18px;
  font-weight: 700;
  text-align: center;
}
.heart {
  color: red;
}
.text {
  text-align: center;
  line-height: 150%;
}
.button {
  margin-top: 20px;
}