.header {
    display: flex;
    justify-content: space-between;
    padding-left: 16px;
    padding-right: 24px;
}

.buttons {
    display: flex;
}

.button {
    flex-shrink: 0;
    font-size: 14px;
    color: #FF6620;
    cursor: pointer;

    &:not(:first-child) {
        margin-left: 10px;
    }
}

.selectors {
    display: flex;
    grid-gap: 30px;
}
.selected {
    &__value {
        font-weight: bold;
        min-width: 40px;
        display: inline-block;
    }
}

@media (min-width: 375px) and (max-width: 839px) {
    .button {
        &:not(:first-child) {
            margin-left: 24px;
        }
    }
    .selectors {
        flex-direction: column;
        grid-gap: 10px;
    }
}

@media (min-width: 840px) {
    .header {
        box-sizing: border-box;
        height: 44px;
        padding: 12px 0;
        margin: 0 32px 0 34px;
        border-bottom: 1px solid #D6D6D7;
    }

    .button {
        position: relative;
        padding-left: 30px;
        color: black;
        font-size: 16px;

        &::after {
            content: "";
            position: absolute;
            left: 0;
            width: 24px;
            height: 18px;
            background-position: center;
            background-repeat: no-repeat;
        }

        &.readButton {
            &::after {
                background-image: url("../../../../../../imgs/double-checkmark.svg");
                background-size: 24px 13px;
            }
        }

        &.deleteButton {
            &::after {
                background-image: url("../../../../../../imgs/trash.svg");
                background-size: 14px 18px;
            }
        }
    }
}