.footer {
    display: flex;
    justify-content: center;
    padding: 16px 16px 40px;
    border-top: 1px solid #D6D6D7;
}

.paginatorContainer {
    width: 346px;
}

@media (min-width: 840px) {
    .footer {
        justify-content: flex-end;
        padding: 16px 0 40px;
        margin-top: auto;
        margin-right: 32px;
    }

    .paginatorContainer {
        width: 434px;
    }
}