.title {
  font-size: 18px;
  font-weight: 700;
  text-align: center;
}

.campName {
  margin-top: 10px;
  font-size: 16px;
  text-align: center;
}

.form {
  margin-top: 20px;
}