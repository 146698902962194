.button {
  width: 340px;
  height: 80px;
  border-radius: 999px;
  border: 1px #EBEBEB solid;
  box-shadow: 3px 3px 10px #00000012;
  background-color: white;
  display: flex;
  align-items: center;
  padding: 5px;
  grid-gap: 20px;
  cursor: pointer;
  transition: box-shadow .15s ease-in-out;
  max-width: 100%;
  text-decoration: none;
  &:hover {
    box-shadow: 7px 7px 10px #00000012;
    transition: box-shadow .15s ease-in-out;
  }
}

.img {
  border-radius: 999px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 110px;
  height: 70px;
  background-image: url("./camps.jpg");
  background-size: cover;
  background-position: center;
}

.textWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-gap: 2px;
}

.new {
  font-weight: 700;
  font-size: 14px;
  letter-spacing: .1em;
}

.title {
  font-weight: 700;
  font-size: 17px;
}

.linkWrapper {
  text-decoration: none;
  color: initial;
  display: inline-block;
  &:visited {
    color: initial;
  }
}